
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import CustomPageTitle from 'components/custom/CustomPageTitle'
import MyOrder from 'components/MyOrder'
import { useParams } from 'react-router-dom'

export function MyOrderPage (): JSX.Element {
  const { orderId } = useParams<{ orderId: string }>()
  return (
    <CustomPageContainer>
      <CustomPageTitle title={`My Order - ${orderId}`} />
      <MyOrder />
    </CustomPageContainer>
  )
}
