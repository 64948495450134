import { useRef, useState, useEffect } from 'react'
import { Table, Button } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import MyOrderTableBody from './MyOrderTableBody'
import MyOrderTableHead from './MyOrderTableHead'
import { useParams } from 'react-router-dom'
import { CustomTooltip } from 'components/custom/CustomTooltip'
import { dataJsonApi } from 'api/api'

export function MyOrder (): JSX.Element {
  const [pdfTitle, setPdfTitle] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [isNotFound, setIsNotFound] = useState({
    isNotFound: false,
    message: ''
  })
  const [tooltipMessage, setTooltipMessage] = useState<string>('N/A')

  const { orderId } = useParams<{ orderId: string }>()
  const componentToPrint = useRef(null)
  const handlePrint = useReactToPrint({
    documentTitle: `biobank_search_order_${orderId}_cardiffuni`,
    pageStyle:
      // eslint-disable-next-line max-len
      '@page { size: auto; margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 20px !important; } }',
    onBeforeGetContent: async () => {
      setPdfTitle(`My Order - ${orderId}`)
      return await Promise.resolve()
    },
    content: () => componentToPrint.current,
    onAfterPrint: async () => {
      setPdfTitle('')
      return await Promise.resolve()
    }
  })

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setTooltipMessage(json.myOrderPage.downloadButton.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <div className="row">
      <div className="w-100 p-lg-0 d-flex flex-column flex-lg-row align-items-lg-center">
        <div className="w-100 p-2 p-lg-0 d-flex flex-column flex-lg-row align-items-lg-center">
          <Button disabled={isFetching || isNotFound.isNotFound} className="m-0 ml-lg-10" onClick={handlePrint}>
            Download PDF / Print
          </Button>
          {tooltipMessage && <CustomTooltip message={tooltipMessage}/>}
        </div>
      </div>

      <div ref={componentToPrint} className="col-md-12">
        <h2 className="pdf-title">{pdfTitle}</h2>
        <Table id="checkout-table" className="border-0" responsive="lg" hover={false}>
          <MyOrderTableHead />
          <MyOrderTableBody isFetching={isFetching} isNotFound={isNotFound} setIsFetching={setIsFetching} setIsNotFound={setIsNotFound} orderId={orderId}/>
        </Table>
      </div>
    </div>
  )
}

export default MyOrder
