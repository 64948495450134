
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import LoginForm from 'components/LoginForm'
import { FootNote } from 'components/FootNote'

export function LoginPage (): JSX.Element {
  return (
    <CustomPageContainer>
      <div className="col-lg-6 ml-auto mr-auto">
        <LoginForm />
      </div>
      <div className="mt-50">
        <FootNote />
      </div>
    </CustomPageContainer>
  )
}
