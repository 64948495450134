
import { WithSearchOutputStateContext } from 'contexts/SearchResultContext/WithSearchResultContext'
import { ISearchOutputState } from 'contexts/SearchResultContext'
import { Spinner } from 'react-bootstrap'

export function SearchResultCount (props: ISearchOutputState): JSX.Element {
  const { allSearchResultsCount, isFetchingCount } = props
  return (
    <span className="text-nowrap">Total results: {isFetchingCount ? <Spinner size="sm" animation="border" /> : allSearchResultsCount}</span>
  )
}

export default WithSearchOutputStateContext(SearchResultCount)
