import { useState, useEffect, useCallback, useRef } from 'react'
import {
  ICurrentUserAction,
  IRegisterData
} from 'contexts/CurrentUserContext'
import { userApi } from 'api/api'
import { WithCurrentUserActionContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { saveToStorage, SESSIONSTORAGE } from 'util/storageHandler'
import { Spinner, Alert } from 'react-bootstrap'
import { ServerError } from 'util/customErrors'
import { Link } from 'react-router-dom'
import { CustomForm } from 'components/custom/CustomForm'

export function RegisterForm (props: ICurrentUserAction): JSX.Element {
  const { changeCurrentUser } = props
  const [isRegistering, setIsRegistering] = useState(false)
  const [isError, setIsError] = useState({ isError: false, message: '' })
  const [registerData, setRegisterData] = useState<IRegisterData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const isMounted = useRef(true)

  const validatePassword = useCallback(() => {
    if (registerData.password.length < 10) {
      setIsError({
        isError: true,
        message: 'Password length should be a minimum of 10 characters'
      })
      return false
    }
    const reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/
    const isPasswordValid = reg.test(registerData.password)
    if (!isPasswordValid) {
      setIsError({
        isError: true,
        message:
          'Password should contain at least one number and include a mixture of upper and lowercase characters.'
      })
      return false
    }
    if (registerData.password !== registerData.confirmPassword) {
      setIsError({
        isError: true,
        message: "Confirm password doesn't match the password"
      })
      return false
    }
    setIsError({ isError: false, message: '' })
    return true
  }, [registerData.confirmPassword, registerData.password])

  const onSubmit = async (): Promise<void> => {
    if (validatePassword()) {
      setIsRegistering(true)
      const user = await userApi.register(registerData).catch((e) => {
        if (e instanceof ServerError && isMounted.current) {
          setIsError({ isError: true, message: e.message })
          setIsRegistering(false)
        } else if (isMounted.current) {
          setIsError({ isError: true, message: 'Email has been used' })
          setIsRegistering(false)
        }
      })
      if (user && isMounted.current) {
        if (user.id) {
          saveToStorage('user', JSON.stringify(user), SESSIONSTORAGE)
          setIsRegistering(false)
          changeCurrentUser(user)
        } else {
          setIsError({ isError: true, message: 'Email has been used' })
          setIsRegistering(false)
        }
      }
    }
  }

  useEffect(() => {
    if (registerData.password.length > 0) {
      validatePassword()
    } else if (
      registerData.password.length === 0 &&
      registerData.confirmPassword.length === 0
    ) {
      setIsError({ isError: false, message: '' })
    }
  }, [registerData.confirmPassword, registerData.password, validatePassword])

  return (
    <CustomForm onSubmit={onSubmit} title="Register">
      <fieldset>
        {isError.isError && (
          <Alert className="mt-5" variant="warning">
            {isError.message}
          </Alert>
        )}
        <div>
          <input
            className="form-control form-control-lg w-100"
            type="text"
            value={registerData.name}
            onChange={(e) =>
              setRegisterData({ ...registerData, name: e.target.value })
            }
            size={30}
            placeholder="Full Name"
            name="name"
            autoFocus={true}
            required
          />
        </div>
        <div className="mt-30">
          <input
            className="form-control form-control-lg w-100"
            type="email"
            value={registerData.email}
            onChange={(e) =>
              setRegisterData({ ...registerData, email: e.target.value })
            }
            size={30}
            placeholder="Email"
            name="email"
            autoFocus={true}
            autoComplete="false"
            required
          />
        </div>
        <div className="mt-30">
          <input
            type="password"
            onChange={(e) =>
              setRegisterData({ ...registerData, password: e.target.value })
            }
            value={registerData.password}
            className="form-control form-control-lg w-100"
            size={30}
            placeholder="Password"
            name="password"
            autoComplete="false"
            required
          />
        </div>
        <div className="mt-30">
          <input
            type="password"
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                confirmPassword: e.target.value
              })
            }
            value={registerData.confirmPassword}
            className="form-control form-control-lg w-100"
            size={30}
            placeholder="Confirm Password"
            name="confirmPassword"
            autoComplete="false"
            required
          />
        </div>
      </fieldset>
        <div className="mt-30 d-flex justify-content-center">
          <button className="btn btn-primary col-12 col-lg-5" type="submit">
            {isRegistering
              ? (
              <>
                <Spinner size="sm" animation="border" variant="light" />
                <span className="ml-5">Registering...</span>
              </>
                )
              : (
                  'Register'
                )}
          </button>
          <input type='submit' hidden />
        </div>
        <div className="mt-50">
          Already have an account ? Click
          <Link className="m-0 p-0" to="/">
            &nbsp;here&nbsp;
          </Link>
          to login &#8594;
        </div>
    </CustomForm>
  )
}

export default WithCurrentUserActionContext(RegisterForm)
