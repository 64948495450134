import { useState, useEffect, useRef, useCallback } from 'react'
import { ReactComponent as AddIcon } from 'assets/svg/list_add.svg'
import { ReactComponent as AddCheckedIcon } from 'assets/svg/list_add_check.svg'
import {
  WithSampleListInputStateContext,
  WithSampleListActionContext
} from 'contexts/SampleListContext/WithSampleListContext'
import { ISampleListInputState, ISampleListAction } from 'contexts/SampleListContext'
import { ISampleItem } from 'contexts/SearchResultContext'
import { NavLink } from 'react-bootstrap'
import TrialOverviewButton from 'components/TrialOverviewButton'
import AddedAlert from '../AddedAlert'
import VolumeWithTooltip from 'components/VolumeWithTooltip'

interface INonGroupedRowProps extends ISampleItem, ISampleListInputState, ISampleListAction { }
export function NonGroupedRow (props: INonGroupedRowProps): JSX.Element {
  const { donorGroup, sampleType, volume, volumeUnit, trial, diagnosis, sampleId, savedSampleIds, handleAddClicked } = props
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(0)
  const isMounted = useRef(true)

  const handleShowMessage = useCallback(() => {
    setCount(count + 1)
    setShow(true)
    setTimeout(
      () => {
        isMounted.current && setShow(false)
      },
      count > 30 ? 4000 : 2000
    )
  }, [count])

  const handleAdd = useCallback(() => {
    handleAddClicked([sampleId], 1)
    handleShowMessage()
  }, [handleAddClicked, handleShowMessage, sampleId])

  useEffect(() => {
    (function isReachMax () {
      if (savedSampleIds.length <= 30) {
        setCount(savedSampleIds.length)
      }
    })()
  }, [savedSampleIds])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  return (
    <tr>
      <td className="p-4 align-middle text-center">{donorGroup}</td>
      <td className="p-4 align-middle">{trial?.id && <TrialOverviewButton trial={trial} />}</td>
      <td className="p-4 align-middle">{diagnosis?.desc}</td>
      <td className="p-4 align-middle">{sampleType?.desc}</td>
      <td className="p-4 align-middle text-center"><VolumeWithTooltip volume={volume} unit={volumeUnit} /></td>
      <td className="pl-4 align-middle text-center">
        {sampleType && !savedSampleIds.includes(sampleId)
          ? <NavLink
            onClick={handleAdd}
            data-toggle="tooltip"
            data-placement="left"
            title="Click to add the sample"
          >
            <AddIcon role="button" />
          </NavLink>
          : <div
            data-toggle="tooltip"
            data-placement="left"
            title="No more sample can be added"
          >
            <AddCheckedIcon />
          </div>
        }
      </td>
      <td><AddedAlert count={count} show={show} handleShowMessage={handleShowMessage} request={1} /></td>
    </tr>
  )
}

export default WithSampleListInputStateContext(WithSampleListActionContext(NonGroupedRow))
