
import { memo } from 'react'
import { WithSearchTableToggleContext } from 'contexts/SearchResultContext/SearchTableContext/WithSearchTableContext'
import { ISearchTableToggleProps } from 'contexts/SearchResultContext/SearchTableContext'

export function SearchTableHead (props: ISearchTableToggleProps): JSX.Element {
  const { groupByDonor } = props
  return (
    <thead>
      <tr>
        <th className="pl-4 pr-4 align-middle text-center">Donor Group</th>
        <th className="pl-4 pr-4 align-middle">Collection ID</th>
        <th className="pl-4 pr-4 align-middle">Diagnosis</th>
        <th className="pl-4 pr-4 align-middle">Sample Type</th>
        <th className="pl-4 pr-4 align-middle text-center">Volume</th>
        {groupByDonor && <th className="pl-4 pr-4 align-middle text-center">Available</th>}
        {groupByDonor && <th className="pl-4 pr-4 align-middle text-center">Request</th>}
        <th className="pl-4 pr-4 align-middle text-center">Add</th>
      </tr>
    </thead>
  )
}

export default WithSearchTableToggleContext(memo(SearchTableHead))
