import { createContext } from 'react'

interface IRoute {
  title: string
  href: string
}

export interface INavProps {
  brand: string
  routes: IRoute[]
  secondaryRoutes: IRoute[]
}

export const NavContext = createContext<INavProps>({ brand: '', routes: [], secondaryRoutes: [] })
