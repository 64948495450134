import {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react'
import {
  SampleListActionContext,
  SampleListInputStateContext,
  SampleListOutputStateContext,
  SampleListFetchingStateContext,
  ISavedGroupedSampleItem,
  SampleListOptionStateContext
} from '.'
import PropTypes from 'prop-types'
import { groupSavedSamples } from 'util/computer'
import { ISearchResults } from 'contexts/SearchResultContext'
import { emptySearchResult } from 'data'
import { sampleApi } from 'api/api'
import {
  getFromStorage,
  saveToStorage,
  removeFromStorage,
  LOCALSTORAGE,
  SESSIONSTORAGE
} from 'util/storageHandler'
import { getSamplesQueryStringGenerator } from 'util/queryStringGenerator'
import { CurrentUserStateContext } from 'contexts/CurrentUserContext'

export const SAMPLEIDS = 'sampleIds'

export const SampleListContextProvider: FC = ({ children }) => {
  const { currentUser } = useContext(CurrentUserStateContext)
  const sortBy = 'donorGroup'
  const LIMIT_PER_REQUEST = 100
  const isMounted = useRef(true)
  const isAdding = useRef(false)
  const AddClickCount = useRef(0)
  const [isInitFetching, setIsInitFetching] = useState(false)
  const [isOtherFetching, setIsOtherFetching] = useState({
    processingIds: [''],
    isIncreasingOrDecreasing: false,
    isRemoving: false,
    isFetchingMore: false
  })
  const [savedSampleIds, setSavedSampleIds] = useState<string[]>([])
  const [savedSampleList, setSavedSampleList] = useState<ISearchResults>(
    emptySearchResult
  )
  const [isNotFound, setIsNotFound] = useState({
    defaultFetch: false,
    fetchMore: false
  })
  const [savedGroupedSampleList, setSavedGroupedSampleList] = useState<
  ISavedGroupedSampleItem[]
  >([])
  const [groupByDonor, setGroupByDonor] = useState(false)
  const [isFrendlyList, setIsFrendlyList] = useState(false)

  const handleAddClicked = useCallback(
    (ids: string[], quantity: number) => {
      if (savedSampleIds.length + quantity > 30) {
        return
      }
      if (
        !isOtherFetching.isRemoving &&
        !isOtherFetching.isIncreasingOrDecreasing
      ) {
        isAdding.current = true
        AddClickCount.current = AddClickCount.current + 1
        setIsInitFetching(true)
        const newIds = ids.filter((id) => !savedSampleIds.includes(id))
        while (quantity < newIds.length) {
          newIds.pop()
        }
        if (newIds) {
          setSavedSampleIds([...savedSampleIds, ...newIds])
        }
      }
    },
    [
      isOtherFetching.isIncreasingOrDecreasing,
      isOtherFetching.isRemoving,
      savedSampleIds
    ]
  )

  const handleRemoveClicked = useCallback(
    (ids: string[], targetIds: string[]) => {
      if (
        !isAdding.current &&
        !isInitFetching &&
        !isOtherFetching.isRemoving &&
        !isOtherFetching.isIncreasingOrDecreasing
      ) {
        setIsOtherFetching({
          ...isOtherFetching,
          isRemoving: true,
          processingIds: targetIds
        })
        const idsToKeep = savedSampleIds.filter((id) => !ids.includes(id))
        setSavedSampleIds(idsToKeep)
        removeFromStorage(currentUser.id + SAMPLEIDS, LOCALSTORAGE)
        removeFromStorage(currentUser.id + SAMPLEIDS, SESSIONSTORAGE)
      }
    },
    [currentUser.id, isInitFetching, isOtherFetching, savedSampleIds]
  )

  const handleIncreaseClicked = useCallback(
    (notAddedsampleId: string, targetIds: string[]) => {
      if (savedSampleIds.length === 30) {
        return
      }
      if (
        !isAdding.current &&
        !isInitFetching &&
        !isOtherFetching.isRemoving &&
        !isOtherFetching.isIncreasingOrDecreasing
      ) {
        setIsOtherFetching({
          ...isOtherFetching,
          isIncreasingOrDecreasing: true,
          processingIds: targetIds
        })
        setSavedSampleIds([...savedSampleIds, notAddedsampleId])
      }
    },
    [isInitFetching, isOtherFetching, savedSampleIds]
  )

  const handleDecreaseClicked = useCallback(
    (sampleId: string, targetIds: string[]) => {
      if (
        !isAdding.current &&
        !isInitFetching &&
        !isOtherFetching.isRemoving &&
        !isOtherFetching.isIncreasingOrDecreasing
      ) {
        setIsOtherFetching({
          ...isOtherFetching,
          isIncreasingOrDecreasing: true,
          processingIds: targetIds
        })
        const idsToKeep = savedSampleIds.filter((id) => id !== sampleId)
        setSavedSampleIds(idsToKeep)
      }
    },
    [isInitFetching, isOtherFetching, savedSampleIds]
  )

  const handleGroupByClicked = useCallback(() => {
    if (!isAdding.current && !isInitFetching && !isFrendlyList) {
      setGroupByDonor(!groupByDonor)
    }
  }, [groupByDonor, isFrendlyList, isInitFetching])

  const handleFriendlyListClicked = useCallback(() => {
    if (!isAdding.current && !isInitFetching && !groupByDonor) {
      setIsFrendlyList(!isFrendlyList)
    }
  }, [groupByDonor, isFrendlyList, isInitFetching])

  useEffect(() => {
    (function initPage () {
      let stored = getFromStorage(currentUser.id + SAMPLEIDS, LOCALSTORAGE)
      if (stored) {
        stored = JSON.parse(stored)
        if (Array.isArray(stored) && stored.length <= 30) {
          setSavedSampleIds(stored)
          setIsInitFetching(true)
        } else {
          removeFromStorage(currentUser.id + SAMPLEIDS, LOCALSTORAGE)
        }
      } else {
        stored = getFromStorage(currentUser.id + SAMPLEIDS, SESSIONSTORAGE)
        if (stored) {
          stored = JSON.parse(stored)
          if (Array.isArray(stored) && stored.length < 30) {
            setSavedSampleIds(stored)
            setIsInitFetching(true)
          } else {
            removeFromStorage(currentUser.id + SAMPLEIDS, SESSIONSTORAGE)
          }
        }
      }
    })()
  }, [currentUser.id])

  useEffect(() => {
    if (isMounted && currentUser.id) {
      const configNotFound = (): void => {
        setIsInitFetching(false)
        setSavedSampleIds([])
        setIsNotFound({ defaultFetch: true, fetchMore: false })
      }
        ; (async function handleSavedSampleIdsChanged (): Promise<void> {
        if (savedSampleIds.length > 0) {
          const results = await sampleApi.get(
            getSamplesQueryStringGenerator(
              savedSampleIds,
              sortBy,
              0,
              LIMIT_PER_REQUEST
            )
          )
          if (results && results.samples.length > 0) {
            setSavedSampleList(results)
            setIsInitFetching(false)
            setIsOtherFetching({
              isIncreasingOrDecreasing: false,
              isRemoving: false,
              isFetchingMore: false,
              processingIds: []
            })
          } else if (results) {
            configNotFound()
          }
          if (
            !saveToStorage(
              currentUser.id + SAMPLEIDS,
              JSON.stringify(savedSampleIds),
              LOCALSTORAGE
            )
          ) {
            saveToStorage(
              currentUser.id + SAMPLEIDS,
              JSON.stringify(savedSampleIds),
              SESSIONSTORAGE
            )
          }
        } else {
          setSavedSampleList(emptySearchResult)
          isAdding.current = false
          AddClickCount.current = 0
          setIsOtherFetching({
            isIncreasingOrDecreasing: false,
            isRemoving: false,
            isFetchingMore: false,
            processingIds: []
          })
        }
      })().catch(() => {
        configNotFound()
        removeFromStorage(currentUser.id + SAMPLEIDS, LOCALSTORAGE)
        removeFromStorage(currentUser.id + SAMPLEIDS, SESSIONSTORAGE)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id, savedSampleIds])

  useEffect(() => {
    if (isMounted && currentUser.id) {
      (async function handleGroupByDonor () {
        if (groupByDonor && savedSampleList.samples.length > 0) {
          if (isAdding.current) {
            AddClickCount.current++
          }
          setIsInitFetching(true)
          const groupedSamples = await groupSavedSamples(
            savedSampleList,
            savedGroupedSampleList,
            groupByDonor
          )
          if (groupedSamples.length > 0) {
            setSavedGroupedSampleList(groupedSamples)
          }
        }
      })().catch(err => err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupByDonor, savedSampleList.samples])

  useEffect(() => {
    (function resetAfterGroupingSuccess () {
      if (isAdding.current) {
        AddClickCount.current--
        setIsInitFetching(true)
      }
      if (AddClickCount.current <= 0) {
        isAdding.current = false
      }
      if (
        !isAdding.current &&
        (savedGroupedSampleList.length > 0 ||
          savedSampleList.samples.length > 0)
      ) {
        setIsInitFetching(false)
        setIsOtherFetching({
          processingIds: [],
          isFetchingMore: false,
          isIncreasingOrDecreasing: false,
          isRemoving: false
        })
      }
    })()
  }, [savedGroupedSampleList, savedSampleList.samples])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  return (
    <SampleListInputStateContext.Provider value={{ savedSampleIds }}>
      <SampleListOptionStateContext.Provider
        value={{ groupByDonor, isFrendlyList }}
      >
        <SampleListFetchingStateContext.Provider
          value={{ isInitFetching, isOtherFetching, isNotFound }}
        >
          <SampleListOutputStateContext.Provider value={{ savedSampleList, savedGroupedSampleList }}>
            <SampleListActionContext.Provider
              value={{
                handleAddClicked,
                handleRemoveClicked,
                handleDecreaseClicked,
                handleIncreaseClicked,
                handleGroupByClicked,
                handleFriendlyListClicked
              }}
            >
              {children}
            </SampleListActionContext.Provider>
          </SampleListOutputStateContext.Provider>
        </SampleListFetchingStateContext.Provider>
      </SampleListOptionStateContext.Provider>
    </SampleListInputStateContext.Provider>
  )
}

SampleListContextProvider.propTypes = {
  children: PropTypes.node
}
