import { FC, useState, useContext, useEffect } from 'react'
import { NavContext, INavProps } from '.'
import PropTypes from 'prop-types'
import { CurrentUserStateContext } from 'contexts/CurrentUserContext'

export const NavContextProvider: FC = ({ children }) => {
  const { currentUser } = useContext(CurrentUserStateContext)
  const [navData, setNavData] = useState<INavProps>({ brand: '', routes: [], secondaryRoutes: [] })
  useEffect(() => {
    setNavData({
      brand: 'Biobank',
      routes: currentUser.id && currentUser.active
        ? [
            {
              title: 'Search',
              href: '/search'
            },
            {
              title: 'Sample List',
              href: '/sampleList'
            },
            {
              title: 'My Orders',
              href: '/myorders'
            }
          ]
        : [],
      secondaryRoutes: [
        {
          title: 'Help',
          href: '/help'
        },
        {
          title: 'Contact',
          href: '/contact'
        },
        {
          title: currentUser.id ? 'Logout' : 'Login',
          href: currentUser.id ? '/logout' : '/'
        }
      ]
    })
  }, [currentUser])
  return <NavContext.Provider value={navData}>{children}</NavContext.Provider>
}

NavContextProvider.propTypes = {
  children: PropTypes.node
}
