import { useState, useRef, useEffect } from 'react'
import { ICurrentUserAction, ILoginData } from 'contexts/CurrentUserContext'
import { userApi } from 'api/api'
import { WithCurrentUserActionContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { saveToStorage, SESSIONSTORAGE } from 'util/storageHandler'
import { Spinner, Alert } from 'react-bootstrap'
import { ServerError } from 'util/customErrors'
import { Link } from 'react-router-dom'
import { CustomForm } from 'components/custom/CustomForm'

export function LoginForm (props: ICurrentUserAction): JSX.Element {
  const { changeCurrentUser } = props
  const [isLoggingin, setIsLogginin] = useState(false)
  const [isNotFound, setIsNotFound] = useState({
    isNotFound: false,
    message: ''
  })
  const [loginData, setLoginData] = useState<ILoginData>({
    email: '',
    password: ''
  })
  const isMounted = useRef(true)

  const onSubmit = async (): Promise<void> => {
    setIsLogginin(true)
    const user = await userApi.login(loginData).catch((e) => {
      if (e instanceof ServerError && isMounted.current) {
        setIsNotFound({ isNotFound: true, message: e.message })
        setIsLogginin(false)
      } else if (isMounted.current) {
        setIsNotFound({ isNotFound: true, message: 'Invalid credentials' })
        setIsLogginin(false)
      }
    })
    if (user && isMounted.current) {
      if (user.id) {
        saveToStorage('user', JSON.stringify(user), SESSIONSTORAGE)
        setIsLogginin(false)
        user.active = JSON.parse(user.active.toString())
        changeCurrentUser(user)
      } else {
        setIsNotFound({ isNotFound: true, message: 'Invalid credentials' })
        setIsLogginin(false)
      }
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <CustomForm onSubmit={onSubmit} title="Login">
      <fieldset>
        {isNotFound.isNotFound && (
          <Alert className="mt-5" variant="warning">
            {isNotFound.message}
          </Alert>
        )}
        <input
          className="form-control form-control-lg w-100"
          type="email"
          value={loginData.email}
          onChange={(e) =>
            setLoginData({ ...loginData, email: e.target.value })
          }
          placeholder="Email"
          name="email"
          autoFocus={true}
          required
        />
        <div className="mt-30">
          <input
            type="password"
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
            value={loginData.password}
            className="form-control form-control-lg w-100"
            placeholder="Password"
            name="password"
            required
          />
        </div>
      </fieldset>
      <div className="mt-30 d-flex justify-content-center">
        <button className="btn btn-primary col-12 col-lg-5" type="submit">
          {isLoggingin
            ? (
              <>
                <Spinner size="sm" animation="border" variant="light" />
                <span className="ml-5">Logging in...</span>
              </>
              )
            : (
                'Login'
              )}
        </button>
        <input type='submit' hidden />
      </div>
      <div className="mt-50">
        Do not have an account ? Click<Link className="m-0 p-0" to="/register">
        &nbsp;here&nbsp;
        </Link>
        to Register &#8594;
      </div>
    </CustomForm>
  )
}

export default WithCurrentUserActionContext(LoginForm)
