import { ComponentType } from 'react'
import {
  ISampleListInputState,
  ISampleListAction,
  SampleListOutputStateContext,
  SampleListActionContext,
  SampleListInputStateContext,
  ISampleListOutputState,
  ISampleListFetchingState,
  SampleListFetchingStateContext,
  ISampleListOptionState,
  SampleListOptionStateContext
} from '.'

export function WithSampleListInputStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISampleListInputState>): JSX.Element {
    return (
      <SampleListInputStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SampleListInputStateContext.Consumer>
    )
  }
}

export function WithSampleListOptionStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISampleListOptionState>): JSX.Element {
    return (
      <SampleListOptionStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SampleListOptionStateContext.Consumer>
    )
  }
}

export function WithSampleListFetchingStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISampleListFetchingState>): JSX.Element {
    return (
      <SampleListFetchingStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SampleListFetchingStateContext.Consumer>
    )
  }
}

export function WithSampleListOutputStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISampleListOutputState>): JSX.Element {
    return (
      <SampleListOutputStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SampleListOutputStateContext.Consumer>
    )
  }
}

export function WithSampleListActionContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISampleListAction>): JSX.Element {
    return (
      <SampleListActionContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SampleListActionContext.Consumer>
    )
  }
}
