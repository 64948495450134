import { memo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ReactComponent as RemoveIcon } from 'assets/svg/list_remove.svg'
import { ICustomModalProps } from 'contexts/CustomModalContext'
import { WithCustomModalContext } from 'contexts/CustomModalContext/WithCustomModalContext'

export function CustomModal ({ options }: ICustomModalProps): JSX.Element {
  const { title, variant, Body, show, handleConfirm, handleShowClose, isAnimation } = options
  return (
    <Modal
      animation={isAnimation === undefined ? true : isAnimation}
      show={show}
      onHide={handleShowClose}
      backdrop={handleConfirm ? 'static' : true}
    >
      <Modal.Header className="d-flex align-item-center pt-15 pb-15" closeButton={false}>
        <Modal.Title>{title ?? ''}</Modal.Title>
        <RemoveIcon role="button" className="ml-auto mr-5" onClick={handleShowClose}>
          Close
        </RemoveIcon>
      </Modal.Header>
      <Modal.Body className="mt-5">{Body ?? ''}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowClose}>
          Close
        </Button>
        {handleConfirm && (
          <Button variant={variant ?? 'primary'} onClick={handleConfirm}>
            Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default WithCustomModalContext(memo(CustomModal))
