import { createContext } from 'react'

export interface IMenuProps {
  isMenuOpen: boolean
  handleMenuOpenClose: () => void
}

export const MenuContext = createContext<IMenuProps>({
  isMenuOpen: false,
  handleMenuOpenClose: () => {

  }
})
