import { memo } from 'react'
import { WithSampleListActionContext } from 'contexts/SampleListContext/WithSampleListContext'
import { ISampleListAction, ISavedGroupedSampleItem } from 'contexts/SampleListContext'
import { CustomIncreaseDecrease } from 'components/custom/CustomIncreaseDecrease'
import { Spinner } from 'react-bootstrap'
import RemoveTableRowButton from 'components/RemoveTableRowButton'
import TrialOverviewButton from 'components/TrialOverviewButton'
import VolumeWithTooltip from 'components/VolumeWithTooltip'

interface IGroupedSampleRowProps extends ISavedGroupedSampleItem, ISampleListAction {
  key: number
  isGroupedWithPrevious: boolean
  isFetching: {
    processingIds: string[]
    isIncreasingOrDecreasing: boolean
    isRemoving: boolean
    isFetchingMore: boolean
  }
  isInitFetching: boolean
}

export function GroupedSampleRow (props: IGroupedSampleRowProps): JSX.Element {
  const {
    key,
    isGroupedWithPrevious,
    sampleIds,
    donorGroup,
    sampleType,
    volume,
    volumeUnit,
    trial,
    diagnosis,
    amount,
    available,
    allSameGroupSampleIds,
    isFetching,
    isInitFetching,
    handleDecreaseClicked,
    handleIncreaseClicked,
    handleRemoveClicked
  } = props

  const handleIncreaseClick = (): void => {
    const notAdded = allSameGroupSampleIds.filter((allSameGroupSampleId) => !sampleIds.includes(allSameGroupSampleId))
    if (notAdded[0] && amount < available) {
      handleIncreaseClicked(notAdded[0], allSameGroupSampleIds)
    }
  }

  const handleDecreaseClick = (): void => {
    const randomSampleInGroup = sampleIds[0]
    if (randomSampleInGroup && amount > 1) {
      handleDecreaseClicked(randomSampleInGroup, allSameGroupSampleIds)
    }
  }

  const handleRemoveClick = (): void => {
    handleRemoveClicked(sampleIds, allSameGroupSampleIds)
  }

  const borderTop = (): string => {
    if (isGroupedWithPrevious || key === 0) {
      return 'border-top-0'
    } else {
      return ''
    }
  }

  return (
    <>
      <tr>
        <td className={`${borderTop()} align-middle text-center p-4`}>{donorGroup}</td>
        <td className={`${borderTop()} align-middle p-4`}>{trial?.id && <TrialOverviewButton trial={trial} />}</td>
        <td className={`${borderTop()} align-middle p-4`}>{diagnosis?.desc}</td>
        <td className={`${borderTop()} align-middle p-4`}>{sampleType?.desc}</td>
        <td className={`${borderTop()} align-middle text-center p-4`}><VolumeWithTooltip volume={volume} unit={volumeUnit} /></td>
        <td className={`${borderTop()} align-middle text-center p-4`}>{available}</td>
        {isFetching.isIncreasingOrDecreasing && isFetching.processingIds === allSameGroupSampleIds
          ? <td className={`${borderTop()} align-middle text-center p-5`}>
            <Spinner animation="border" />
          </td>
          : <td className={`${borderTop()} align-middle text-center p-5`}>
            <CustomIncreaseDecrease
              number={amount}
              handleIncreaseClicked={handleIncreaseClick}
              handleDecreaseClicked={handleDecreaseClick}
            />
          </td>
        }
        {isFetching.isRemoving && isFetching.processingIds === allSameGroupSampleIds
          ? <td className={`${borderTop()} align-middle text-center p-5`}>
            <Spinner animation="border" />
          </td>
          : <td className={`${borderTop()} align-middle text-center p-5`}>
            {sampleType?.id &&
              <RemoveTableRowButton
                message={'Delete this sample(s)? Are you sure?'}
                isFetching={isFetching.isIncreasingOrDecreasing || isFetching.isRemoving || isInitFetching}
                handleRemoveClicked={handleRemoveClick}
              />
            }
          </td>
        }
      </tr>
    </>
  )
}

export default WithSampleListActionContext(memo(GroupedSampleRow))
