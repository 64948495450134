
import { NavLink } from 'react-router-dom'
import styles from './index.module.scss'
import { WithNavContext } from 'contexts/HeaderContext/NavContext/WithNavContext'
import { INavProps } from 'contexts/HeaderContext/NavContext'

function NavLinks (props: INavProps): JSX.Element {
  const { routes } = props
  return (
    <ul className="nav nav-global-links d-none d-lg-flex">
      {routes.map((route, key) => (
        <li key={key} className="nav-item mr-30">
          <NavLink className={`nav-link border-0 ${styles.Link}`} to={route.href}>
            {route.title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default WithNavContext(NavLinks)
