import { useState, FC, useMemo, useCallback } from 'react'
import { MenuContext } from '.'
import PropTypes from 'prop-types'

export const MenuContextProvider: FC = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuOpenClose = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true)
      document.body.classList.remove('menu-closing')
      document.body.classList.add('menu-open')
    } else {
      setIsMenuOpen(false)
      document.body.classList.remove('menu-open')
      document.body.classList.add('menu-closing')
    }
  }, [isMenuOpen])

  const value = useMemo(() => {
    return { isMenuOpen, handleMenuOpenClose }
  }, [isMenuOpen, handleMenuOpenClose])

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

MenuContextProvider.propTypes = {
  children: PropTypes.node
}
