
import './index.scss'
import { CustomTooltip } from '../CustomTooltip'
export interface ICustomToggleButtonProps {
  name: string
  isChecked: boolean
  handleToggleClicked: () => void
  tooltipMessage?: string
  disabled?: boolean
}

export function CustomToggleButton (props: ICustomToggleButtonProps): JSX.Element {
  const { name, isChecked, handleToggleClicked, tooltipMessage, disabled } = props
  return (
    <div className="ml-0 mr-0 p-0 col-lg-12 col-8 d-flex align-items-center">
      <h4 className="mb-0">{name}</h4>
      <label className="ml-auto ml-lg-10 mb-0 custom-toggle-button" role="label">
        <input defaultChecked={isChecked} disabled={disabled} onClick={handleToggleClicked} type="checkbox" />
        <span className="slider round"></span>
      </label>
      {tooltipMessage && <CustomTooltip message={tooltipMessage} />}
    </div>
  )
}
