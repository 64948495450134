import { useState, useEffect, useCallback, useMemo } from 'react'
import { ReactComponent as RemoveIcon } from 'assets/svg/list_remove.svg'
import { WithCustomModalContext } from 'contexts/CustomModalContext/WithCustomModalContext'
import { ICustomModalProps } from 'contexts/CustomModalContext'

export interface IRemoveTableRowButtonProps extends ICustomModalProps {
  isFetching: boolean
  handleRemoveClicked: () => void
  message: string
}

export function RemoveTableRowButton (props: IRemoveTableRowButtonProps): JSX.Element {
  const { message, isFetching, handleRemoveClicked, changeOptions, options } = props
  const [show, setShow] = useState(false)
  const handleModalOpenClose = useCallback(() => {
    setShow(!show)
  }, [show])

  const handleModalConfirm = useCallback(() => {
    changeOptions({
      ...options,
      show: false
    })
    handleRemoveClicked()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeOptions, handleRemoveClicked])

  useEffect(() => {
    if (!isFetching) {
      changeOptions({
        title: 'Delete Confirmation',
        Body: message,
        show: show,
        handleShowClose: handleModalOpenClose,
        handleConfirm: handleModalConfirm
      })
    }
  }, [message, changeOptions, handleModalConfirm, handleModalOpenClose, isFetching, show])

  return useMemo(() => <RemoveIcon onClick={handleModalOpenClose} role="button" />, [handleModalOpenClose])
}

export default WithCustomModalContext(RemoveTableRowButton)
