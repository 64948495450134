import { memo } from 'react'
export interface ICustomLoadMoreProps {
  showMore: boolean
  list: unknown[]
  index: number
  handleLoadMoreClicked: () => void
}

export function CustomLoadMore (props: ICustomLoadMoreProps): JSX.Element {
  const { showMore, list, index, handleLoadMoreClicked } = props
  return (
    <>
      {showMore && list.length > index && (
        <p className="facet-item" style={{ color: '#045BC6' }} role="button" onClick={handleLoadMoreClicked}>
          Show More...
        </p>
      )}
    </>
  )
}

export default memo(CustomLoadMore)
