
import { Table } from 'react-bootstrap'
import MyOrdersTableBody from './MyOrdersTableBody'
import MyOrdersTableHead from './MyOrdersTableHead'

export function MyOrders (): JSX.Element {
  return (
    <div className="row">
      <div className="col-md-12">
        <Table id="checkout-table" className="border-0" responsive="lg" hover={true}>
          <MyOrdersTableHead />
          <MyOrdersTableBody />
        </Table>
      </div>
    </div>
  )
}

export default MyOrders
