
import { ISearchInputState, ISearchAction, ISearchFetchingState, IFetchingProps } from 'contexts/SearchResultContext'
import {
  WithSearchInputStateContext,
  WithSearchActionContext,
  WithSearchFetchingStateContext
} from 'contexts/SearchResultContext/WithSearchResultContext'
import CustomSingleInputForm from 'components/custom/CustomSingleInputForm'
import { ReactComponent as SearchIcon } from 'assets/svg/searchIcon.svg'
import { WithFilterFacetStateContext } from 'contexts/FilterFacetContext/WithFilterFacetContext'
import { IFilterFacetState } from 'contexts/FilterFacetContext'
const MobileICON = (): JSX.Element => <SearchIcon />

type SamePropSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>

interface ISearchBarProps extends ISearchInputState, ISearchAction, ISearchFetchingState, IFilterFacetState {
  isNotFound: SamePropSpread<IFetchingProps, { isNotFound: boolean, message: string }>
}

export function SearchBar (props: ISearchBarProps): JSX.Element {
  const { handleSearchSubmit, searchInput, handleSearchInputChange, isFetchingResults, isNotFound, filtersToDisplay } = props

  return <CustomSingleInputForm
    formLabel="Search"
    inputValue={searchInput}
    handleInputChange={handleSearchInputChange}
    handleSubmit={handleSearchSubmit}
    inputProps={{ spellCheck: true, placeholder: 'Enter a diagnosis term eg. fit and well' }}
    isFetching={isFetchingResults.submit}
    isError={{ isError: isNotFound.submit, message: 'Result Not Found' }}
    MobileICON={MobileICON}
    inputOptions={filtersToDisplay.diagnosis.map(d => d.desc)}
  />
}

export default WithSearchFetchingStateContext(
  WithSearchInputStateContext(WithSearchActionContext(WithFilterFacetStateContext(SearchBar)))

)
