import { useState, useEffect, useRef } from 'react'
import { ICurrentUserAction, ICurrentUserState } from 'contexts/CurrentUserContext'
import { userApi } from 'api/api'
import { WithCurrentUserActionContext, WithCurrentUserStateContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { saveToStorage, SESSIONSTORAGE } from 'util/storageHandler'
import { Spinner, Alert } from 'react-bootstrap'
import { ServerError } from 'util/customErrors'
import { CustomForm } from 'components/custom/CustomForm'
import { OTPInputs } from './OTPInputs'
import styles from './index.module.scss'

interface IOTPFormProps extends ICurrentUserAction, ICurrentUserState {

}

export function OTPForm (props: IOTPFormProps): JSX.Element {
  const { changeCurrentUser, currentUser } = props
  const [isVerifying, setIsVerifying] = useState(false)
  const [isNotFound, setIsNotFound] = useState({
    isNotFound: false,
    message: ''
  })
  const [counter, setCounter] = useState(60)
  const [passcode, setPasscode] = useState<string>('')
  const isMounted = useRef(true)

  const handleResend = (): void => {
    setCounter(60)
    userApi.sendPasscode().catch(() => {
      setIsNotFound({ isNotFound: true, message: 'OTP was not sent' })
    })
  }

  const onSubmit = async (): Promise<void> => {
    setIsVerifying(true)
    const user = await userApi.verify(passcode).catch((e) => {
      if (e instanceof ServerError && isMounted.current) {
        setIsNotFound({ isNotFound: true, message: e.message })
        setIsVerifying(false)
      } else if (isMounted.current) {
        setIsNotFound({ isNotFound: true, message: 'Invalid credentials' })
        setIsVerifying(false)
      }
    })
    if (user && isMounted.current) {
      if (user.id) {
        saveToStorage('user', JSON.stringify(user), SESSIONSTORAGE)
        setIsVerifying(false)
        user.active = JSON.parse(user.active.toString())
        changeCurrentUser(user)
      } else {
        setIsNotFound({ isNotFound: true, message: 'Invalid credentials' })
        setIsVerifying(false)
      }
    }
  }

  useEffect(() => {
    userApi.sendPasscode().catch(() => {
      setIsNotFound({ isNotFound: true, message: 'OTP was not sent' })
    })
  }, [])

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
    return () => { }
  }, [counter])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  return (
    <CustomForm onSubmit={onSubmit} title="Verification">
      <small className='w-100 d-sm-flex justify-content-center'>A One Time Passcode was just sent to <b>&nbsp;{currentUser.email}</b></small>
      <fieldset>
        {isNotFound.isNotFound && (
          <Alert className="mt-5" variant="warning">
            {isNotFound.message}
          </Alert>
        )}
        <OTPInputs
          autoFocus
          isNumberInput
          length={6}
          className={`w-100 ${styles.otpContainer}`}
          inputClassName={styles.otpInput}
          onChangeOTP={(otp) => setPasscode(otp)}
        />
      </fieldset>
      <div className="mt-30 d-sm-flex justify-content-between">
        <button disabled={counter > 0} className="btn btn-primary col-12 col-sm-5" onClick={handleResend} type='button'>
          {counter > 0 ? `Resend (${counter})` : 'Resend'}
        </button>
        <button className="btn btn-primary col-12 col-sm-5" type="submit">
          {isVerifying
            ? (
              <>
                <Spinner size="sm" animation="border" variant="light" />
                <span className="ml-5">Verifying...</span>
              </>
              )
            : 'VERIFY'}
        </button>
        <input type="submit" hidden />
      </div>
    </CustomForm>
  )
}

export default WithCurrentUserActionContext(WithCurrentUserStateContext(OTPForm))
