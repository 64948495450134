import { useState, FC, useEffect, useCallback, useRef } from 'react'
import {
  ISearchResults,
  SearchInputStateContext,
  SearchOutputStateContext,
  SearchActionContext,
  SearchFetchingStateContext
} from '.'
import { sampleApi } from 'api/api'
import PropTypes from 'prop-types'
import { IFilterSubItem, IFiltersToDisplay } from 'contexts/FilterFacetContext'
import { emptySearchResult, emptyFilters } from 'data'
import { searchQueryStringGenerator } from 'util/queryStringGenerator'
import { saveToStorage, getFromStorage, SESSIONSTORAGE, removeFromStorage } from 'util/storageHandler'

export const SearchResultContextProvider: FC = ({ children }) => {
  const SEARCH_BY = 'diagnosis'
  const sortBy = 'donorGroup'
  const FILTERSTOFETCH = 'filtersToFetch'
  const SEARCHINPUT = 'searchInput'
  const LIMIT_PER_REQUEST = 50
  const offset = useRef(0)
  const isInitRender = useRef(true)
  const [searchInput, setSearchInput] = useState('')
  const [filtersToFetch, setFiltersToFetch] = useState<IFiltersToDisplay>(emptyFilters)
  const [searchResults, setRearchResults] = useState<ISearchResults>(emptySearchResult)
  const [allSearchResultsCount, setAllSearchResultsCount] = useState<number>(0)
  const [isFetchingCount, setIsFetchingCount] = useState(false)
  const [isFetchingResults, setIsFetchingResults] = useState({ loadMore: false, submit: false })
  const [isNotFound, setIsNotFound] = useState({ loadMore: false, submit: false })

  const handleFilterClicked = useCallback((filterItemName: string, filterSubItem: IFilterSubItem) => {
    let selectedFilters: IFilterSubItem[] = [...filtersToFetch[filterItemName]]
    const isSelected = selectedFilters.some((selectedSubItem) => selectedSubItem.id === filterSubItem.id)
    if (isSelected) {
      selectedFilters = selectedFilters.filter((selectedSubItem) => selectedSubItem.id !== filterSubItem.id)
    } else {
      selectedFilters.push(filterSubItem)
    }
    setFiltersToFetch({ ...filtersToFetch, [filterItemName]: [...selectedFilters] })
  }, [filtersToFetch])

  const handleSearchInputChange = useCallback((input: string) => {
    saveToStorage(SEARCHINPUT, input, SESSIONSTORAGE)
    setSearchInput(input)
  }, [])

  // Fetching results implementation
  const configResultNotfound = (isFetchMore: boolean): void => {
    setIsFetchingResults({ loadMore: false, submit: false })
    if (isFetchMore) {
      setIsNotFound({ loadMore: true, submit: false })
    } else {
      setIsNotFound({ loadMore: false, submit: true })
    }
  }

  const fetchSearchResults = useCallback(
    async (sortBy: string, offset: number, isFetchMore: boolean) => {
      setIsNotFound({ loadMore: false, submit: false })
      setIsFetchingResults({ loadMore: isFetchMore, submit: !isFetchMore })
      const searchText = `${SEARCH_BY}:*${searchInput}*`
      let filtersQueryString = searchQueryStringGenerator(
        filtersToFetch,
        searchText,
        sortBy,
        offset,
        LIMIT_PER_REQUEST
      )
      const results = await sampleApi.get(filtersQueryString).catch(() => {
        configResultNotfound(isFetchMore)
        if (!isFetchMore) {
          setRearchResults({ samples: [] })
        }
      })
      if (results && results.samples.length < 1) {
        configResultNotfound(isFetchMore)
        if (!isFetchMore) {
          setRearchResults({ samples: [] })
        }
      } else if (results) {
        setIsFetchingResults({ loadMore: false, submit: false })
        if (isFetchMore) {
          const combineSamples = [...searchResults.samples, ...results.samples]
          setRearchResults({ samples: combineSamples })
        } else {
          setRearchResults({ ...results })
        }
      }
      if (!isFetchMore) {
        filtersQueryString = searchQueryStringGenerator(filtersToFetch, searchText, sortBy, 0, 1)
        setIsFetchingCount(true)
        const count = await sampleApi.getCount(filtersQueryString).catch((err) => {
          console.error(err)
          setIsFetchingCount(false)
        })
        if (count || count === 0) {
          setAllSearchResultsCount(count)
          setIsFetchingCount(false)
        } else {
          setIsFetchingCount(false)
        }
      }
    },
    [filtersToFetch, searchInput, searchResults.samples]
  )

  const handleSearchSubmit = useCallback(async () => {
    offset.current = 0
    await fetchSearchResults(sortBy, offset.current, false)
  }, [fetchSearchResults, sortBy])

  const handleLoadMore = useCallback(async () => {
    offset.current = offset.current + LIMIT_PER_REQUEST
    await fetchSearchResults(sortBy, offset.current, true)
  }, [fetchSearchResults, sortBy])

  useEffect(() => {
    (function saveFiltersChanges () {
      if (!isInitRender.current) {
        saveToStorage(FILTERSTOFETCH, JSON.stringify(filtersToFetch), SESSIONSTORAGE)
      }
      isInitRender.current = false
    })()
  }, [filtersToFetch])

  useEffect(() => {
    (function initPage () {
      const storedFilters = getFromStorage(FILTERSTOFETCH, SESSIONSTORAGE)
      const storedSearchInput = getFromStorage(SEARCHINPUT, SESSIONSTORAGE)
      if (storedFilters) {
        try {
          setFiltersToFetch(JSON.parse(storedFilters))
        } catch {
          setFiltersToFetch(emptyFilters)
          removeFromStorage(FILTERSTOFETCH, SESSIONSTORAGE)
        }
      }
      if (storedSearchInput) {
        setSearchInput(storedSearchInput)
      }
    })()
  }, [])

  return (
    <SearchInputStateContext.Provider value={{ searchInput, filtersToFetch, sortBy }}>
      <SearchFetchingStateContext.Provider value={{ isFetchingResults, isNotFound }}>
        <SearchOutputStateContext.Provider value={{ searchResults, allSearchResultsCount, isFetchingCount }}>
          <SearchActionContext.Provider
            value={{
              handleSearchSubmit,
              handleSearchInputChange,
              handleFilterClicked,
              handleLoadMore
            }}
          >
            {children}
          </SearchActionContext.Provider>
        </SearchOutputStateContext.Provider>
      </SearchFetchingStateContext.Provider>
    </SearchInputStateContext.Provider>
  )
}

SearchResultContextProvider.propTypes = {
  children: PropTypes.node
}
