
import { Route, Redirect, useLocation } from 'react-router-dom'
import { ICurrentUserState } from 'contexts/CurrentUserContext'
import { WithCurrentUserStateContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { LoginPage } from 'pages/LoginPage'
import { ILocationProps } from './PrivateRoute'

interface ILoginRouteProps extends ICurrentUserState {
  children: React.ReactNode
  [x: string]: unknown
}

function LoginRoute (props: ILoginRouteProps): JSX.Element {
  const { currentUser, children, ...rest } = props
  const { state } = useLocation<ILocationProps>()
  return (
    <Route {...rest}>
      {!currentUser.id && !currentUser.active
        ? <LoginPage />
        : <Redirect to={{
          pathname: '/verify',
          state: { from: state?.from ?? '/search' }
        }} />
      }
    </Route>
  )
}

export default WithCurrentUserStateContext(LoginRoute)
