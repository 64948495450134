import { IFilterSubItem, IFiltersToDisplay } from 'contexts/FilterFacetContext'
import { IGroupedSampleItem } from 'contexts/SearchResultContext/SearchTableContext'

// Get qs for searching samples
export function searchQueryStringGenerator (
  filtersToFetch: IFiltersToDisplay,
  searchText: string,
  sortBy: string,
  offset: number,
  LIMIT_PER_REQUEST: number
): string {
  const queryString =
    Object.keys(filtersToFetch)
      .map((key: string) => {
        const filterSubItems: IFilterSubItem[] = filtersToFetch[key]
        return `${key}=${filterSubItems.map((i: IFilterSubItem) => i.id).join('||')}`
      })
      .join('&') +
    `&searchText=${searchText}` +
    `&sort=${sortBy}&offset=${offset}&limit=${LIMIT_PER_REQUEST}`
  return queryString
}

// Get qs for getting samples
export function getSamplesQueryStringGenerator (
  sampleIds: string[],
  sortBy?: string,
  offset?: number,
  LIMIT_PER_REQUEST?: number
): string {
  const queryString =
    'sampleId=' + sampleIds.join('||') + `&sort=${sortBy ?? ''}&offset=${offset ?? ''}&limit=${LIMIT_PER_REQUEST ?? ''}`
  return queryString
}

// Get qs for getting samples that are in the same group
export function getSameGroupSamplesQueryStringGenerator ({
  donorGroup,
  sampleType,
  volume,
  trial,
  diagnosis
}: IGroupedSampleItem): string {
  const s = `donorGroup=${donorGroup || ''}&sampleType=${sampleType?.id ? sampleType.id : ''}&volume=${
    volume?.id ? volume.id : ''
  }&trial=${trial?.id ? trial.id : ''}&diagnosis=${diagnosis?.id ? diagnosis.id : ''}`

  return s
}
