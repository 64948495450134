import Header from 'components/Header'
import 'assets/sass/main.sass'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Footer } from 'components/Footer'
import { SearchPage } from 'pages/SearchPage'
import { SampleListPage } from 'pages/SampleListPage'
import { HelpPage } from 'pages/HelpPage'
import { ContactPage } from 'pages/ContactPage'
import CustomModal from 'components/custom/CustomModal'
import { MyOrderPage } from 'pages/MyOrderPage'
import PrivateRoute from 'pages/Route/PrivateRoute'
import VerifyRoute from 'pages/Route/VerifyRoute'
import { MyOrdersPage } from 'pages/MyOrdersPage'
import LoginRoute from 'pages/Route/LoginRoute'
import RegisterRoute from 'pages/Route/RegisterRoute'

export function App (): JSX.Element {
  return (
    <div className="r-section-homepage with-site-alert">
      <Header />
      <Switch>

        <Route exact path="/help">
          <HelpPage />
        </Route>
        <Route exact path="/contact">
          <ContactPage />
        </Route>

        <LoginRoute exact path="/" />
        <RegisterRoute exact path="/register" />
        <VerifyRoute exact path='/verify' />

        <PrivateRoute exact path='/search'>
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute exact path="/sampleList">
          <SampleListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/myorders">
          <MyOrdersPage />
        </PrivateRoute>
        <PrivateRoute exact path="/myorder/:orderId">
          <MyOrderPage />
        </PrivateRoute>

        <Redirect from="*" to="/" />

      </Switch>
      <CustomModal />
      <Footer />
    </div>
  )
}

export default App
