import { createContext } from 'react'
import { ISearchResults } from 'contexts/SearchResultContext'
import { emptySearchResult } from 'data'
import { IGroupedSampleItem } from 'contexts/SearchResultContext/SearchTableContext'

// React search components props
export interface ISampleListInputState {
  savedSampleIds: string[]
}

export interface ISampleListOptionState {
  groupByDonor: boolean
  isFrendlyList: boolean
}

export interface ISampleListFetchingState {
  isInitFetching: boolean
  isOtherFetching: {
    processingIds: string[]
    isIncreasingOrDecreasing: boolean
    isRemoving: boolean
    isFetchingMore: boolean
  }
  isNotFound: { defaultFetch: boolean, fetchMore: boolean }
}
export interface ISavedGroupedSampleItem extends IGroupedSampleItem {
  available: number
  allSameGroupSampleIds: string[]
}

export interface ISampleListOutputState {
  savedSampleList: ISearchResults
  savedGroupedSampleList: ISavedGroupedSampleItem[]
}

export interface ISampleListAction {
  handleAddClicked: (ids: string[], quantity: number) => void
  handleRemoveClicked: (ids: string[], targetIds: string[]) => void
  handleGroupByClicked: () => void
  handleIncreaseClicked: (notAddedsampleId: string, targetIds: string[]) => void
  handleDecreaseClicked: (id: string, targetIds: string[]) => void
  handleFriendlyListClicked: () => void
}

export const SampleListInputStateContext = createContext<ISampleListInputState>({
  savedSampleIds: []
})

export const SampleListOptionStateContext = createContext<ISampleListOptionState>({
  groupByDonor: false,
  isFrendlyList: false
})

export const SampleListFetchingStateContext = createContext<ISampleListFetchingState>({
  isInitFetching: true,
  isOtherFetching: {
    processingIds: [],
    isIncreasingOrDecreasing: false,
    isRemoving: false,
    isFetchingMore: false
  },
  isNotFound: { defaultFetch: false, fetchMore: false }
})

export const SampleListOutputStateContext = createContext<ISampleListOutputState>({
  savedSampleList: emptySearchResult,
  savedGroupedSampleList: []
})

export const SampleListActionContext = createContext<ISampleListAction>({
  handleAddClicked: () => {

  },
  handleRemoveClicked: () => {

  },
  handleGroupByClicked: () => {

  },
  handleIncreaseClicked: () => {

  },
  handleDecreaseClicked: () => {

  },
  handleFriendlyListClicked: () => {

  }
})
