import { useMemo } from 'react'
import { ReactComponent as CheckboxIcon } from 'assets/svg/checkbox.svg'
import { ReactComponent as CheckboxSelectedIcon } from 'assets/svg/checkbox-selected.svg'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { NavLink } from 'react-bootstrap'
import { ISearchInputState, ISearchAction } from 'contexts/SearchResultContext'
import {
  WithSearchInputStateContext,
  WithSearchActionContext
} from 'contexts/SearchResultContext/WithSearchResultContext'

export interface IFilterSubItemProps extends ISearchAction, ISearchInputState {
  filterItemName: string
  filterSubItem: IFilterSubItem
}

export function FilterSubItem (props: IFilterSubItemProps): JSX.Element {
  const { handleFilterClicked, filterItemName, filterSubItem, filtersToFetch } = props

  const filterSubItemName = useMemo(() => {
    let name = filterSubItem.desc ? filterSubItem.desc : filterSubItem.id
    name = name.replace(/([A-Z])/g, ' $1').trim()
    return name.charAt(0).toUpperCase() + name.slice(1)
  }, [filterSubItem])

  const handleClick = (): void => {
    handleFilterClicked(filterItemName, filterSubItem)
  }

  const isSelected: boolean = filtersToFetch[filterItemName].some((i: IFilterSubItem) => i.id === filterSubItem.id)

  const ICON = useMemo(() => (isSelected ? <CheckboxSelectedIcon /> : <CheckboxIcon />), [isSelected])

  return (
    <li className="facet-item">
      {filterSubItemName && filtersToFetch[filterItemName] && (
        <NavLink onClick={handleClick}>
          {ICON}
          {filterSubItemName}
        </NavLink>
      )}
    </li>
  )
}

export default WithSearchInputStateContext(WithSearchActionContext(FilterSubItem))
