
import { GlobalNav } from './GlobalNav'
import SecondaryNav from './SecondaryNav'
import GlobalNavMobile from './GlobalNavMobile'

function Header (): JSX.Element {
  return (
    <header>
      <SecondaryNav />
      <GlobalNav />
      <GlobalNavMobile />
    </header>
  )
}

export default Header
