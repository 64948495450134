import { FC } from 'react'
import { MenuContextProvider } from 'contexts/HeaderContext/MenuContext/MenuContextProvider'
import { NavContextProvider } from 'contexts/HeaderContext/NavContext/NavContextProvider'
import { CurrentUserContextProvider } from 'contexts/CurrentUserContext/CurrentUserContextProvider'
import PropTypes from 'prop-types'
import { SearchTableContextProvider } from './SearchResultContext/SearchTableContext/SearchTableContextProvider'
import { SearchResultContextProvider } from './SearchResultContext/SearchContextProvider'
import { FilterFacetContextProvider } from './FilterFacetContext/FilterFacetContextProvider'
import { SampleListContextProvider } from './SampleListContext/SampleListProvider'
import { CustomModalContextProvider } from './CustomModalContext/CustomModalContextProvider'

// Order matters. Last one will be the parent provider
const providers = [
  CustomModalContextProvider,
  SampleListContextProvider,
  SearchTableContextProvider,
  SearchResultContextProvider,
  FilterFacetContextProvider,
  MenuContextProvider,
  NavContextProvider,
  CurrentUserContextProvider
]

export const RootContextProvider: FC = ({ children }) => {
  return (
    // Accumulate the component (App) and all contexts
    <>
      {providers.reduce((accumulator, Provider) => {
        return <Provider>{accumulator}</Provider>
      }, children)}
    </>
  )
}

RootContextProvider.propTypes = {
  children: PropTypes.node
}
