import { ComponentType } from 'react'
import { ICustomModalProps, CustomModalContext } from '.'

export function WithCustomModalContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ICustomModalProps>): JSX.Element {
    return (
      <CustomModalContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </CustomModalContext.Consumer>
    )
  }
}
