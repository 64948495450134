import { FC, useState, useContext, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  SearchTableToggleContext,
  SearchTablePaginContext,
  IGroupedSampleItem,
  ComputedSearchOutputStateContext
} from '.'
import { SearchOutputStateContext } from 'contexts/SearchResultContext'
import { groupSamples } from 'util/computer'

export const SearchTableContextProvider: FC = ({ children }) => {
  const { searchResults } = useContext(SearchOutputStateContext)
  const [groupedSearchResults, setGroupedSearchResults] = useState<IGroupedSampleItem[]>([])
  const [groupByDonor, setGroupByDonor] = useState(false)
  const [sortBy] = useState('diagnosis')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalResults, setTtotalResults] = useState(0)
  const [allPageNumbers, setAllPageNumbers] = useState([0])
  const RESULTS_PER_PAGE = 15

  const handleToggleClicked = useCallback(() => {
    setGroupByDonor(!groupByDonor)
  }, [groupByDonor])

  const changeTablePage = useCallback((number: number) => {
    setCurrentPage(number)
  }, [])

  const slicedSearchResults = useMemo(() => {
    const data = [...searchResults.samples]
    const indexOfLastResult = currentPage * RESULTS_PER_PAGE
    const indexOfFirstResult = indexOfLastResult - RESULTS_PER_PAGE
    // data = data.sort(
    //   (a, b) => a[`${sortBy}`] && a[`${sortBy}`].id && a[`${sortBy}`].id.localeCompare(b[`${sortBy}`].id)
    // );
    return data.slice(indexOfFirstResult, indexOfLastResult)
  }, [searchResults, currentPage])

  useEffect(() => {
    (function calculatePageNumbers () {
      const pages = []
      for (let i = 1; i <= Math.ceil(totalResults / RESULTS_PER_PAGE); i++) {
        pages.push(i)
      }
      setAllPageNumbers(pages)
    })()
  }, [totalResults, groupByDonor])

  useEffect(() => {
    (function handleGroupByDonor () {
      if (groupByDonor && searchResults.samples.length) {
        const indexOfLastResult = currentPage * RESULTS_PER_PAGE
        const indexOfFirstResult = indexOfLastResult - RESULTS_PER_PAGE
        const computed = Object.entries(groupSamples([...searchResults.samples], groupByDonor))
        setTtotalResults(computed.length)
        const sliced = computed.slice(indexOfFirstResult, indexOfLastResult).map((entry) => entry[1])
        setGroupedSearchResults(sliced)
      } else {
        setTtotalResults(searchResults.samples.length)
      }
    })()
  }, [searchResults, currentPage, groupByDonor, sortBy])

  return (
    <ComputedSearchOutputStateContext.Provider value={{ groupedSearchResults, slicedSearchResults }}>
      <SearchTableToggleContext.Provider value={{ groupByDonor, handleToggleClicked }}>
        <SearchTablePaginContext.Provider value={{ changeTablePage, currentPage, allPageNumbers }}>
          {children}
        </SearchTablePaginContext.Provider>
      </SearchTableToggleContext.Provider>
    </ComputedSearchOutputStateContext.Provider>
  )
}

SearchTableContextProvider.propTypes = {
  children: PropTypes.node
}
