
import { memo } from 'react'
import { WithSearchOutputStateContext } from 'contexts/SearchResultContext/WithSearchResultContext'
import { ISearchOutputState } from 'contexts/SearchResultContext'
import { IComputedSearchOutputState, ISearchTableToggleProps } from 'contexts/SearchResultContext/SearchTableContext'
import {
  WithSearchTableToggleContext,
  WithComputedSearchOutputStateContext
} from 'contexts/SearchResultContext/SearchTableContext/WithSearchTableContext'
import GroupededRow from './GroupedRow'
import NonGroupedRow from './NonGroupedRow'

interface SearchTableBodyProps extends ISearchOutputState, IComputedSearchOutputState, ISearchTableToggleProps { }

export function SearchTableBody (props: SearchTableBodyProps): JSX.Element {
  const { searchResults, slicedSearchResults, groupedSearchResults, groupByDonor } = props
  return (
    <tbody>
      {searchResults.samples.length > 0 && groupByDonor
        ? groupedSearchResults.map((groupedSearchResult, key) => {
          const previous = groupedSearchResults[key - 1]
          return <GroupededRow key={key} {...groupedSearchResult} isGroupedWithPrevious={previous && previous.donorGroup === groupedSearchResult.donorGroup} />
        })
        : searchResults.samples.length > 0 &&
        slicedSearchResults.map((slicedSearchResult, key) => {
          return <NonGroupedRow key={key} {...slicedSearchResult} />
        })}
    </tbody>
  )
}

export default WithSearchOutputStateContext(
  WithComputedSearchOutputStateContext(WithSearchTableToggleContext(memo(SearchTableBody)))
)
