
import { Route, Redirect, useLocation } from 'react-router-dom'
import { ICurrentUserState } from 'contexts/CurrentUserContext'
import { WithCurrentUserStateContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { VerificationPage } from 'pages/VerificationPage'
import { ILocationProps } from './PrivateRoute'

interface IVerifyRouteProps extends ICurrentUserState {
  [x: string]: unknown
}

function VerifyRoute (props: IVerifyRouteProps): JSX.Element {
  const { currentUser, children, ...rest } = props
  const { state } = useLocation<ILocationProps>()
  return (
    <Route {...rest}>
      {currentUser.id && !currentUser.active
        ? <VerificationPage />
        : <Redirect to={{
          pathname: state?.from ?? '/search'
        }} />
      }
    </Route>
  )
}

export default WithCurrentUserStateContext(VerifyRoute)
