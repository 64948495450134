import { ComponentType } from 'react'
import {
  SearchInputStateContext,
  ISearchInputState,
  ISearchOutputState,
  SearchOutputStateContext,
  ISearchAction,
  SearchActionContext,
  ISearchFetchingState,
  SearchFetchingStateContext
} from '.'

export function WithSearchInputStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchInputState>): JSX.Element {
    return (
      <SearchInputStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchInputStateContext.Consumer>
    )
  }
}

export function WithSearchFetchingStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchFetchingState>): JSX.Element {
    return (
      <SearchFetchingStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchFetchingStateContext.Consumer>
    )
  }
}

export function WithSearchActionContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchAction>): JSX.Element {
    return (
      <SearchActionContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchActionContext.Consumer>
    )
  }
}

export function WithSearchOutputStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchOutputState>): JSX.Element {
    return (
      <SearchOutputStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchOutputStateContext.Consumer>
    )
  }
}
