import { ComponentType } from 'react'
import { ICurrentUserState, ICurrentUserAction, CurrentUserStateContext, CurrentUserActionContext } from '.'

export function WithCurrentUserStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ICurrentUserState>): JSX.Element {
    return (
      <CurrentUserStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </CurrentUserStateContext.Consumer>
    )
  }
}

export function WithCurrentUserActionContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ICurrentUserAction>): JSX.Element {
    return (
      <CurrentUserActionContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </CurrentUserActionContext.Consumer>
    )
  }
}
