import { ComponentType } from 'react'
import { MenuContext, IMenuProps } from '.'

export function WithMenuContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof IMenuProps>): JSX.Element {
    return (
      <MenuContext.Consumer>{(contextProps) => <Component {...(props as T)} {...contextProps} />}</MenuContext.Consumer>
    )
  }
}
