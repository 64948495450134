import { useState, useEffect } from 'react'
import { ISampleListAction, ISampleListFetchingState, ISampleListOptionState } from 'contexts/SampleListContext'
import {
  WithSampleListActionContext,
  WithSampleListFetchingStateContext,
  WithSampleListOptionStateContext
} from 'contexts/SampleListContext/WithSampleListContext'
import { CustomToggleButton } from 'components/custom/CustomToggleButton'
import { dataJsonApi } from 'api/api'

export interface ISampleListToggleProps extends ISampleListAction, ISampleListFetchingState, ISampleListOptionState { }

export function SampleListToggle (props: ISampleListToggleProps): JSX.Element {
  const { groupByDonor, handleGroupByClicked, isInitFetching, isOtherFetching } = props
  const [message, setMessage] = useState<string>('')

  const isDisable =
    isInitFetching ||
    isOtherFetching.isIncreasingOrDecreasing ||
    isOtherFetching.isRemoving ||
    isOtherFetching.isFetchingMore

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setMessage(json.groupByDonor.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className="mb-10 mr-lg-20">
        <CustomToggleButton
          isChecked={groupByDonor}
          disabled={isDisable}
          handleToggleClicked={handleGroupByClicked}
          name="Group By Donor"
          tooltipMessage={message}
        />
      </div>
    </div>
  )
}

export default WithSampleListFetchingStateContext(
  WithSampleListActionContext(WithSampleListOptionStateContext(SampleListToggle))
)
