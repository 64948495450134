import { useState, FC, useEffect, useMemo, useCallback } from 'react'
import { FilterFacetStateContext, FilterModalContext, IFiltersToDisplay } from '.'
import { sampleApi } from 'api/api'
import PropTypes from 'prop-types'
import { emptyFilters } from 'data'

export const FilterFacetContextProvider: FC = ({ children }) => {
  const [filtersToDisplay, setFiltersToDisplay] = useState<IFiltersToDisplay>(emptyFilters)
  const [isFilterModalOn, setIsFilterModalOn] = useState(false)
  const [isFetchingFilters, setIsFetchingFilters] = useState(false)
  const [isNotFound, setIsNotFound] = useState({ isNotFound: false, message: '' })

  const handleFilterModalClick = useCallback(() => {
    setIsFilterModalOn(!isFilterModalOn)
  }, [isFilterModalOn])

  const fetchFilters = async (): Promise<void> => {
    setIsFetchingFilters(true)
    const filters = await sampleApi.list()
    if (filters) {
      const filteredArray = Object
        .keys(filters)
        .filter(name => name !== 'postMortem' && name !== 'sport' && name !== 'volume' && name !== 'volumeUnit')
        .sort((a, b) => b === 'sampleType' || (b === 'tissueType' && a !== 'sampleType') || (b === 'consentRestrictions' && a[0] !== 'sampleType' && a[0] !== 'tissueType') ? 1 : -1
        ) as Array<keyof IFiltersToDisplay>
      const newFilters = filteredArray.reduce((acc, key) => Object.assign(acc, { [key]: filters[key].filter(i => i.numSamples > 0) }), {}) as IFiltersToDisplay

      setFiltersToDisplay(newFilters)
      setIsFetchingFilters(false)
    }
  }

  useEffect(() => {
    fetchFilters().catch((e) => {
      setIsFetchingFilters(false)
      setIsNotFound({ isNotFound: true, message: e.message })
    })
  }, [])

  const filterFacetStateValue = useMemo(() => {
    return { filtersToDisplay, isFetchingFilters, isNotFound }
  }, [filtersToDisplay, isFetchingFilters, isNotFound])

  const filterFacetActionValue = useMemo(() => {
    return { handleFilterModalClick, isFilterModalOn }
  }, [handleFilterModalClick, isFilterModalOn])

  return (
    <FilterFacetStateContext.Provider value={filterFacetStateValue}>
      <FilterModalContext.Provider value={filterFacetActionValue}>{children}</FilterModalContext.Provider>
    </FilterFacetStateContext.Provider>
  )
}

FilterFacetContextProvider.propTypes = {
  children: PropTypes.node
}
