export const getSampleTypeNameToDisplay = (originalFilterItemName: string): string => {
  if (!originalFilterItemName) {
    return 'Requesting Filters...'
  } else if (originalFilterItemName === 'trial') {
    return 'COLLECTION'
  } else if (originalFilterItemName === 'gender') {
    return 'SEX'
  } else if (originalFilterItemName === 'consentRestrictions') {
    return 'SAMPLE USES'
  } else {
    return originalFilterItemName.replace(/([A-Z])/g, ' $1').trim().toUpperCase()
  }
}
