
import { UniLogo } from 'components/Header/GlobalNav/UniLogo'
import Menu from 'components/Header/GlobalNav/MenuButton'
import NavLinks from 'components/Header/GlobalNav/NavLinks'
import NavBrand from 'components/Header/GlobalNav/NavBrand'

export function GlobalNav (): JSX.Element {
  return (
    <nav className="nav-global nav-homepage-primary" aria-label="Primary navigation homepage">
      <div className="navbar">
        <Menu />
        <UniLogo />
        <NavBrand />
        <NavLinks />
      </div>
    </nav>
  )
}
