import { createContext } from 'react'
import { emptyCurrentUser } from 'data'

export interface IUser {
  id: string
  username: string
  name: string
  email: string
  active: boolean
}

export interface IRegisterData {
  name: string
  email: string
  password: string
  confirmPassword: string
}

export interface ILoginData {
  email: string
  password: string
}

export interface ICurrentUserState {
  currentUser: IUser
}

export interface ICurrentUserAction {
  changeCurrentUser: (user: IUser) => void
}

export const CurrentUserStateContext = createContext<ICurrentUserState>({
  currentUser: emptyCurrentUser
})

export const CurrentUserActionContext = createContext<ICurrentUserAction>({
  changeCurrentUser: () => {}
})
