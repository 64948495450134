
import { memo } from 'react'
import CustomPagination from 'components/custom/CustomPagination'

import {
  WithSearchOutputStateContext,
  WithSearchActionContext,
  WithSearchFetchingStateContext
} from 'contexts/SearchResultContext/WithSearchResultContext'
import { ISearchTablePaginProps } from 'contexts/SearchResultContext/SearchTableContext'

import { WithSearchTablePaginContext } from 'contexts/SearchResultContext/SearchTableContext/WithSearchTableContext'
import { ISearchOutputState, ISearchAction, ISearchFetchingState } from 'contexts/SearchResultContext'

interface ISearchPagination extends ISearchTablePaginProps, ISearchOutputState, ISearchAction, ISearchFetchingState { }
export function SearchPagination (props: ISearchPagination): JSX.Element {
  const { currentPage, changeTablePage, handleLoadMore, isFetchingResults, allPageNumbers, isNotFound } = props

  return <>
    <CustomPagination
      changePage={changeTablePage}
      currentPage={currentPage}
      handleLoadMore={handleLoadMore}
      isLoadMoreFetching={isFetchingResults.loadMore}
      isLoadMoreNotFound={isNotFound.loadMore}
      allPageNumbers={allPageNumbers}
    />
    {}
  </>
}

export default WithSearchFetchingStateContext(
  WithSearchOutputStateContext(WithSearchTablePaginContext(WithSearchActionContext(memo(SearchPagination))))
)
