import { useEffect } from 'react'
import FilterFacet from 'components/FilterFacet'
import { IFilterModalProps } from 'contexts/FilterFacetContext'
import { WithIFilterModalContext } from 'contexts/FilterFacetContext/WithFilterFacetContext'
import { Button } from 'react-bootstrap'
import { WithCustomModalContext } from 'contexts/CustomModalContext/WithCustomModalContext'
import { ICustomModalProps } from 'contexts/CustomModalContext'

interface IMobileFilterLaunchProps extends IFilterModalProps, ICustomModalProps { }
export function MobileFilterLaunch (props: IMobileFilterLaunchProps): JSX.Element {
  const { isFilterModalOn, handleFilterModalClick, changeOptions } = props

  useEffect(() => {
    changeOptions({
      isAnimation: false,
      show: isFilterModalOn,
      title: 'Select Filters',
      Body: <FilterFacet />,
      handleShowClose: handleFilterModalClick
    })
  }, [changeOptions, handleFilterModalClick, isFilterModalOn])
  return (
    <Button
      variant="warning"
      className="btn-block mt-10 mb-0 d-lg-none border-0"
      style={{ backgroundColor: '#e9e9e9' }}
      id="launch-facet-menu-popup"
      onClick={handleFilterModalClick}
    >
      Filter results
    </Button>
  )
}

export default WithCustomModalContext(WithIFilterModalContext(MobileFilterLaunch))
