
import FilterItem from './FilterItem'
import { Alert } from 'react-bootstrap'
import { IFilterFacetState, IFilterModalProps } from 'contexts/FilterFacetContext'
import {
  WithFilterFacetStateContext,
  WithIFilterModalContext
} from 'contexts/FilterFacetContext/WithFilterFacetContext'

interface IFilterFacetProps extends IFilterFacetState, IFilterModalProps { }
export function FilterFacet (props: IFilterFacetProps): JSX.Element {
  const { filtersToDisplay, isFetchingFilters, isFilterModalOn, isNotFound } = props

  return (
    <aside className={` ${!isFilterModalOn ? 'd-none' : ''} d-lg-block`}>
      {filtersToDisplay && !isFetchingFilters && !isNotFound.isNotFound
        ? Object.keys(filtersToDisplay).map((filterItemName) => {
          return <FilterItem
            key={filterItemName}
            filterItemName={filterItemName}
            filterSubItems={filtersToDisplay[filterItemName] ? filtersToDisplay[filterItemName] : []}
          />
        })
        : !isNotFound.isNotFound
            ? <FilterItem filterItemName={''} filterSubItems={[]} />
            : <Alert variant="danger">{isNotFound.isNotFound && isNotFound.message}</Alert>
      }
    </aside>
  )
}

export default WithFilterFacetStateContext(WithIFilterModalContext(FilterFacet))

// // To hide empty filters
// {
// filtersToDisplay[filterItemName].length > 0 && (
//  <FilterItem filterItemName={filterItemName} filterSubItems={filterSubItems} isFetchingFilters={isFetchingFilters} />
//   );
// }
