import { FC } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'

export const CustomPageContainer: FC = ({ children }) => {
  return (
    <Container className="mt-50 w-100">
      <Row className="row">
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}

CustomPageContainer.propTypes = {
  children: PropTypes.node
}
