import { memo } from 'react'

export interface IPageTitle {
  title: string
}

function CustomPageTitle ({ title }: IPageTitle): JSX.Element {
  return (
    <h1 className="page-title mb-30" id="page-title">
      {title}
    </h1>
  )
}

export default memo(CustomPageTitle)
