
import { useState, useEffect } from 'react'
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import CustomPageTitle from 'components/custom/CustomPageTitle'
import { Alert, Row } from 'react-bootstrap'
import FilterFacet from 'components/FilterFacet'
import SearchBar from 'components/SearchBar'
import MobileFilterLaunch from 'components/MobileFilterLaunch'
import SelectedFacets from 'components/SelectedFacets'
import SearchTable from 'components/SearchTable'
import SampleListIcon from 'components/SampleListIcon'
import SearchTableToggle from 'components/SearchTableToggle'
import { dataJsonApi } from 'api/api'
import SearchResultCount from 'components/SearchResultCount'

export function SearchPage (): JSX.Element {
  const [topMessage, setTopMessage] = useState('')
  const [searchMessage, setSearchMessage] = useState('')

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setTopMessage(json.searchPage.top.message)
      setSearchMessage(json.searchPage.underSearchBox.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <CustomPageContainer>
      <div className="d-flex justify-content-center">
        <Alert className="col-lg-10" variant="warning">
          {topMessage}
        </Alert>
      </div>
      <div className="d-flex">
        <CustomPageTitle title="Search" />
        <div className="ml-auto">
          <SampleListIcon />
        </div>
      </div>
      <Row>
        <div className="col-lg-3 p-0">
          <FilterFacet />
        </div>
        <section className="col-md-12 col-lg-9" id="search-results">
          <SearchBar />
          <MobileFilterLaunch />
          <Alert className="mt-10 mt-lg-0" variant="warning">
            {searchMessage}
          </Alert>
          <div className="mt-5">
            <SelectedFacets />
          </div>
          <div className="d-flex mb-3 mt-3">
            <div className="col-8">
              <SearchTableToggle />
            </div>
            <div className="d-flex justify-content-end align-items-center col-4">
              <SearchResultCount />
            </div>
          </div>
          <SearchTable />
        </section>
      </Row>
    </CustomPageContainer>
  )
}
