
import { Spinner } from 'react-bootstrap'

interface ITableLoadingSpinnerProps {
  isFetching: boolean
  message: string
}
export function TableLoadingSpinner (props: ITableLoadingSpinnerProps): JSX.Element {
  const { isFetching, message } = props
  return (
    <>
      {isFetching && (
        <tr>
          <td colSpan={100} className="text-lg-center">
            <div className="d-flex justify-content-lg-center align-items-center w-100">
              <Spinner animation="border" role="status" />
              <div className="ml-10"> {message} </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default TableLoadingSpinner
