import { createContext } from 'react'
import { IFilterSubItem, IFiltersToDisplay } from 'contexts/FilterFacetContext'
import { emptyFilters, emptySearchResult } from 'data'

export interface ISampleItem {
  sampleId: string
  donorGroup: string
  ageRange: IFilterSubItem
  gender: IFilterSubItem
  volumeUnit: IFilterSubItem
  diagnosis: IFilterSubItem
  sampleType: IFilterSubItem
  trial: IFilterSubItem
  volume: IFilterSubItem
  postMortem: IFilterSubItem
  tissueType: IFilterSubItem
  metastasis: IFilterSubItem
  storageType: IFilterSubItem
  sport: IFilterSubItem
  consentRestrictions: IFilterSubItem[]
}

export interface ISearchResults {
  samples: ISampleItem[]
}

// React search components props
export interface ISearchInputState {
  searchInput: string
  filtersToFetch: IFiltersToDisplay
  sortBy: string
}

export interface IFetchingProps {
  loadMore: boolean
  submit: boolean
}
export interface ISearchFetchingState {
  isFetchingResults: IFetchingProps
  isNotFound: IFetchingProps
}

export interface ISearchOutputState {
  searchResults: ISearchResults
  allSearchResultsCount: number
  isFetchingCount: boolean
}

export interface ISearchAction {
  handleSearchInputChange: (s: string) => void
  handleFilterClicked: (filterItemName: string, filterSubItem: IFilterSubItem) => void
  handleSearchSubmit: () => void
  handleLoadMore: () => void
}

export const SearchInputStateContext = createContext<ISearchInputState>({
  searchInput: '',
  filtersToFetch: emptyFilters,
  sortBy: ''
})

export const SearchFetchingStateContext = createContext<ISearchFetchingState>({
  isFetchingResults: { loadMore: false, submit: false },
  isNotFound: { loadMore: false, submit: false }
})

export const SearchOutputStateContext = createContext<ISearchOutputState>({
  searchResults: emptySearchResult,
  allSearchResultsCount: 0,
  isFetchingCount: false
})

export const SearchActionContext = createContext<ISearchAction>({
  handleSearchInputChange: () => {

  },
  handleFilterClicked: () => {

  },
  handleSearchSubmit: () => {

  },
  handleLoadMore () {

  }
})
