
import { ReactComponent as BookmarkIcon } from 'assets/svg/bookmark.svg'
import { ISampleListInputState } from 'contexts/SampleListContext'
import { WithSampleListInputStateContext } from 'contexts/SampleListContext/WithSampleListContext'
import './index.scss'
import { Link } from 'react-router-dom'

export function SampleListIcon ({ savedSampleIds }: ISampleListInputState): JSX.Element {
  return (
    <Link to="/sampleList" className="text-dark">
      <div
        className="cart-icon"
        data-toggle="tooltip"
        data-placement="left"
        title="Number of samples you added. Click to go to your sample list"
      >
        <BookmarkIcon className="shopping-icon" />
        <span className="item-count" style={{ left: savedSampleIds.length >= 10 ? '14px' : '18px' }}>
          {savedSampleIds.length}
        </span>
      </div>
    </Link>
  )
}

export default WithSampleListInputStateContext(SampleListIcon)
