
import { NavLink } from 'react-router-dom'
import { WithNavContext } from 'contexts/HeaderContext/NavContext/WithNavContext'
import { INavProps } from 'contexts/HeaderContext/NavContext'

function SecondaryNav (props: INavProps): JSX.Element {
  const { secondaryRoutes } = props
  return (
    <nav className="nav-secondary" aria-label="Secondary navigation homepage">
      <div className="navbar">
        <ul className="nav">
          {secondaryRoutes.map((route, key) => (
            <li key={key} className="nav-item">
              <NavLink className="nav-link" to={route.href}>
                {route.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default WithNavContext(SecondaryNav)
