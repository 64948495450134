
export function SampleListHead (): JSX.Element {
  return (
    <thead>
      <tr>
        <th className={'align-middle text-center p-4'}>Donor Group</th>
        <th className={'align-middle p-4'}>Collection ID</th>
        <th className={'align-middle p-4'}>Diagnosis</th>
        <th className={'align-middle p-4'}>Sample Type</th>
        <th className={'align-middle text-center p-4'}>Volume</th>
        <th className="align-middle text-center p-3">Available</th>
        <th className={'align-middle text-center p-3'}>Request</th>
        <th className=" align-middle text-center p-4 bi-cursor">Remove All</th>
      </tr>
    </thead>
  )
}

export default SampleListHead
