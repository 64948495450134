
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import CustomPageTitle from 'components/custom/CustomPageTitle'
import SampleListTable from 'components/SampleListTable'
import SampleListToggle from 'components/SampleListToggle'
import CheckoutButton from 'components/CheckoutButton'
import { Alert } from 'react-bootstrap'
import { dataJsonApi } from 'api/api'
import { useState, useEffect } from 'react'

export function SampleListPage (): JSX.Element {
  const [topMessage, setTopMessage] = useState('')

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setTopMessage(json.sampleListPage.top.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <CustomPageContainer>
      <CustomPageTitle title="Sample List" />
      <div className="d-flex">
        <Alert className="col-lg-12" variant="warning">
          {topMessage}
        </Alert>
      </div>
      <div className='d-flex mt-10'>
        <SampleListToggle />
        <div className="ml-sm-auto">
          <CheckoutButton />
        </div>
      </div>

      <div className='mt-10'>
        <SampleListTable />
      </div>
    </CustomPageContainer>
  )
}
