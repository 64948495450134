
import { memo } from 'react'
import GroupedSampleRow from '../GroupedSampleRow'
import NonGroupedSampleRow from '../NonGroupedSampleRow'
import { ISampleListFetchingState, ISampleListOutputState } from 'contexts/SampleListContext'

import {
  WithSampleListFetchingStateContext,
  WithSampleListOutputStateContext
} from 'contexts/SampleListContext/WithSampleListContext'
import TableLoadingSpinner from 'components/TableLoadingSpinner'

export interface ISampleListBodyProps extends ISampleListFetchingState, ISampleListOutputState {
  groupByDonor: boolean
}

export function SampleListBody (props: ISampleListBodyProps): JSX.Element {
  const { savedSampleList, groupByDonor, savedGroupedSampleList, isInitFetching, isOtherFetching } = props
  return (
    <tbody>
      <TableLoadingSpinner message={'Requesting and processing the samples...'} isFetching={isInitFetching} />
      {savedSampleList.samples.length > 0 && groupByDonor
        ? savedGroupedSampleList.map((savedGroupedSample, key) => {
          const previous = savedGroupedSampleList[key - 1]
          return (
            <GroupedSampleRow
              key={key}
              isGroupedWithPrevious={previous && previous.donorGroup === savedGroupedSample.donorGroup}
              isFetching={isOtherFetching}
              isInitFetching={isInitFetching}
              {...savedGroupedSample}
            />
          )
        })
        : savedSampleList.samples.map((sample, key) => {
          return (
            <NonGroupedSampleRow key={key} isFetching={isOtherFetching} isInitFetching={isInitFetching} {...sample} />
          )
        })}
    </tbody>
  )
}

export default WithSampleListFetchingStateContext(WithSampleListOutputStateContext(memo(SampleListBody)))
