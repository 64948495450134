
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export interface ICustomTooltipProps {
  message: string
  svgEle?: string
}

export function CustomTooltip (props: ICustomTooltipProps): JSX.Element {
  const { message, svgEle } = props

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 30, hide: 30 }}
      overlay={<Tooltip id="button-tooltip-2">{message}</Tooltip>}
    >
      {svgEle === 'star'
        ? <strong style={{ cursor: 'pointer' }}>*</strong>
        : <svg style={{ cursor: 'pointer', height: '40px', width: '50px' }} fill="#000000" xmlns={'http://www.w3.org/2000/svg'} viewBox="0 0 30 30" version="1.1" x="0px" y="0px">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(7.000000, 7.000000)" fill="#000000">
              <path d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M7.08229167,9.96549479 L8.75807292,9.96549479 C8.76588546,9.66861831 8.80690067,9.42350357 8.88111979,9.23014323 C8.95533891,9.03678289 9.08815008,8.84830821 9.27955729,8.66471354 L9.95338542,8.03776042 C10.2385431,7.76041528 10.4455723,7.48893362 10.5744792,7.22330729 C10.7033861,6.95768096 10.7678385,6.66276204 10.7678385,6.33854167 C10.7678385,5.59635046 10.5285831,5.02409055 10.0500651,4.62174479 C9.57154709,4.21939903 8.89674915,4.01822917 8.02565104,4.01822917 C7.15064667,4.01822917 6.47096596,4.23600043 5.98658854,4.67154948 C5.50221112,5.10709853 5.25611983,5.71549089 5.24830729,6.49674479 L7.22877604,6.49674479 C7.23658858,6.20377458 7.31275969,5.97135503 7.45729167,5.79947917 C7.60182364,5.62760331 7.79127487,5.54166667 8.02565104,5.54166667 C8.53346608,5.54166667 8.78736979,5.83658559 8.78736979,6.42643229 C8.78736979,6.668621 8.71217523,6.89029847 8.56178385,7.09147135 C8.41139248,7.29264424 8.19166811,7.51432171 7.90260417,7.75651042 C7.61354022,7.99869913 7.40455794,8.28482908 7.27565104,8.61490885 C7.14674415,8.94498863 7.08229167,9.39517944 7.08229167,9.96549479 Z M6.84205729,11.7467448 C6.84205729,12.0358087 6.94654843,12.2731111 7.15553385,12.4586589 C7.36451927,12.6442067 7.62525885,12.7369792 7.93776042,12.7369792 C8.25026198,12.7369792 8.51100156,12.6442067 8.71998698,12.4586589 C8.9289724,12.2731111 9.03346354,12.0358087 9.03346354,11.7467448 C9.03346354,11.4576808 8.9289724,11.2203785 8.71998698,11.0348307 C8.51100156,10.8492829 8.25026198,10.7565104 7.93776042,10.7565104 C7.62525885,10.7565104 7.36451927,10.8492829 7.15553385,11.0348307 C6.94654843,11.2203785 6.84205729,11.4576808 6.84205729,11.7467448 Z"></path>
            </g>
          </g>
        </svg>
      }
    </OverlayTrigger>
  )
}
