
import Table from 'react-bootstrap/Table'
import SearchTableHead from './SearchTableHead'
import SearchPagination from './SearchPagination'
import SearchTableBody from './SearchTableBody'

export function SearchTable (): JSX.Element {
  return (
    <div className="mb-3 mt-3">
      <Table responsive="xl" hover className="border-0 w-95 mt-3 mb-3">
        <SearchTableHead />
        <SearchTableBody />
      </Table>
      <SearchPagination />
    </div>
  )
}

export default SearchTable
