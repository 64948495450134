
import { Route, Redirect, useHistory } from 'react-router-dom'
import { ICurrentUserState } from 'contexts/CurrentUserContext'
import { WithCurrentUserStateContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'

export interface ILocationProps {
  from: string
}

interface IPrivateRouteProps extends ICurrentUserState {
  children: React.ReactNode
  [x: string]: unknown
}

function PrivateRoute (props: IPrivateRouteProps): JSX.Element {
  const { currentUser, children, ...rest } = props
  const history = useHistory()
  return (
    <Route {...rest}>
      {currentUser.id && currentUser.active
        ? children
        : currentUser.id && !currentUser.active
          ? <Redirect to={{
            pathname: '/verify',
            state: { from: history.location.pathname }
          }} />
          : <Redirect to={{
            pathname: '/',
            state: { from: history.location.pathname }
          }}
          />
      }
    </Route>
  )
}

export default WithCurrentUserStateContext(PrivateRoute)
