
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import CustomPageTitle from 'components/custom/CustomPageTitle'
import MyOrders from 'components/MyOrders'

export function MyOrdersPage (): JSX.Element {
  return (
    <CustomPageContainer>
      <CustomPageTitle title='My Orders' />
      <MyOrders />
    </CustomPageContainer>
  )
}
