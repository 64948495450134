
import { dataJsonApi } from 'api/api'
import { CustomTooltip } from 'components/custom/CustomTooltip'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { useState, useEffect } from 'react'

export interface IVolumeWithTooltipProps {
  volume: IFilterSubItem
  unit: IFilterSubItem
}

export function VolumeWithTooltip (props: IVolumeWithTooltipProps): JSX.Element {
  const { volume, unit } = props
  const [tooltipMessage, setTooltipMessage] = useState('')

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setTooltipMessage(json.volumeReminder.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <>
      {volume?.desc}{unit?.desc}{tooltipMessage && volume?.from === 'Container' && <CustomTooltip message={tooltipMessage} svgEle="star"/>}
    </>
  )
}

export default VolumeWithTooltip
