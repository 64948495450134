import { useState } from 'react'
import { Spinner, Alert, ListGroup } from 'react-bootstrap'
import { CustomTooltip } from '../CustomTooltip'

interface ICustomSingleInputFormProps {
  formLabel: string
  handleInputChange: (s: string) => void
  handleSubmit: () => void
  inputValue: string
  inputOptions?: string[]
  tooltipMessage?: string
  isFetching?: boolean
  isError?: { isError: boolean, message: string }
  MobileICON?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & Record<string, unknown>>
  inputProps?: Omit<JSX.IntrinsicElements['input'], 'type' | 'value' | 'onChange'>
  formProps?: Omit<JSX.IntrinsicElements['form'], 'onSubmit' | 'className'>
  submitButtonProps?: Omit<JSX.IntrinsicElements['button'], 'type' | 'className' | 'style'>
}

export function CustomSingleInputForm (props: ICustomSingleInputFormProps): JSX.Element {
  const { formLabel, isFetching, isError, inputOptions, handleInputChange, handleSubmit, tooltipMessage, MobileICON, inputValue, inputProps, submitButtonProps, formProps } = props
  const [showInputOptions, setShowInputOptions] = useState(false)

  return (
    <form
      {...formProps}
      className="form m-0 w-100"
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <div className="form-row">
        <div className="col-md-12 col-lg-8 my-1">
          <label className="sr-only">{formLabel}</label>
          <input
            type="text"
            className="form-control position-relative"
            value={inputValue}
            {...inputProps}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={() => setShowInputOptions(true)}
            onBlur={() => setShowInputOptions(false)}
          />
          <ListGroup style={{ zIndex: 50, overflow: 'auto', maxHeight: '56rem' }} className={`position-absolute w-100 pr-10 ${inputOptions && showInputOptions ? 'd-block' : 'd-none'}`}>
            {inputOptions?.filter(input => input.toLowerCase().includes(inputValue.toLowerCase())).map(input => {
              return (
                <ListGroup.Item key={input} type="button" action onMouseDown={() => handleInputChange(input)}>{input}</ListGroup.Item>
              )
            })}
          </ListGroup>
          <button
            type="submit"
            className="btn btn-link d-lg-none position-absolute"
            style={{ top: 0, right: 0 }}
            {...submitButtonProps}
          >
            {isFetching
              ? <Spinner className="icon" variant="primary" animation="grow" size="sm" />
              : MobileICON && <MobileICON />
            }
          </button>
        </div>
        <div className="col-auto my-1 d-none d-lg-flex">
          <button type="submit" className="btn btn-primary btn-large" {...submitButtonProps}>
            {formLabel}
          </button>
          {tooltipMessage && tooltipMessage.length > 0 && <CustomTooltip message={tooltipMessage} />}
        </div>
        <div className="col-auto my-1 d-none d-lg-block">
          {isFetching && (
            <>
              <Spinner variant="primary" animation="grow" size="sm" />
              <Spinner variant="primary" animation="grow" />
            </>
          )}
        </div>
        {isError?.isError && <Alert variant={'warning'}>{isError.message}</Alert>}
      </div>
      <input type="hidden" name="collection" value="profiles" />
    </form>
  )
}

export default CustomSingleInputForm
