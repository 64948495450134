import ReactDOM from 'react-dom'
import App from './App'
import { RootContextProvider } from 'contexts/RootContextProvider'
import { HashRouter as Router } from 'react-router-dom'

ReactDOM.render(
  <Router basename={process.env.REACT_APP_BASE_URL ?? '/'}>
    <RootContextProvider>
      <App />
    </RootContextProvider>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
