import { createContext } from 'react'
import { emptyFilters } from 'data'

export interface IFilterSubItem {
  id: string
  desc: string
  numSamples: number
  notes?: string
  from?: string
}
export interface IFilters {
  sampleType: IFilterSubItem[]
  tissueType: IFilterSubItem[]
  gender: IFilterSubItem[]
  ageRange: IFilterSubItem[]
  postMortem: IFilterSubItem[]
  storageType: IFilterSubItem[]
  consentRestrictions: IFilterSubItem[]
  diagnosis: IFilterSubItem[]
  sport: IFilterSubItem[]
  metastasis: IFilterSubItem[]
  trial: IFilterSubItem[]
  volume: IFilterSubItem[]
  volumeUnit: IFilterSubItem[]
}

export type IFiltersToDisplay = Omit<IFilters, 'postMortem' | 'sport' | 'volume' | 'volumeUnit'>

// React search components props
export interface IFilterFacetState {
  filtersToDisplay: IFiltersToDisplay
  isFetchingFilters: boolean
  isNotFound: { isNotFound: boolean, message: string }
}

export interface IFilterModalProps {
  isFilterModalOn: boolean
  handleFilterModalClick: () => void
}

export const FilterFacetStateContext = createContext<IFilterFacetState>({
  filtersToDisplay: emptyFilters,
  isFetchingFilters: false,
  isNotFound: { isNotFound: false, message: '' }
})

export const FilterModalContext = createContext<IFilterModalProps>({
  isFilterModalOn: false,
  handleFilterModalClick: () => {}
})
