import { useState, FC, useCallback } from 'react'

import PropTypes from 'prop-types'
import { defaultModalOptions } from 'data'
import { CustomModalContext, ICustomModalOptions } from '.'

export const CustomModalContextProvider: FC = ({ children }) => {
  const [options, setOptions] = useState<ICustomModalOptions>(defaultModalOptions)
  const changeOptions = useCallback((options: ICustomModalOptions) => {
    setOptions(options)
  }, [])
  return <CustomModalContext.Provider value={{ options, changeOptions }}>{children}</CustomModalContext.Provider>
}

CustomModalContextProvider.propTypes = {
  children: PropTypes.node
}
