
export function MyOrdersTableHead (): JSX.Element {
  return (
    <thead>
      <tr>
        <th className='align-middle p-4'>ID</th>
        <th className='align-middle p-4'>Created At</th>
        <th className='align-middle p-4 text-right'>View</th>
        <th className='align-middle p-4 text-right'>Delete</th>
      </tr>
    </thead>
  )
}

export default MyOrdersTableHead
