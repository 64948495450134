import { createContext } from 'react'
import { defaultModalOptions } from 'data'

export interface ICustomModalOptions {
  show: boolean
  handleShowClose: () => void
  title?: string
  Body?: string | React.ReactNode
  handleConfirm?: () => void
  variant?: string
  isAnimation?: boolean
}
export interface ICustomModalProps {
  options: ICustomModalOptions
  changeOptions: (options: ICustomModalOptions) => void
}

export const CustomModalContext = createContext<ICustomModalProps>({
  options: defaultModalOptions,
  changeOptions: () => {

  }
})
