
import { Alert } from 'react-bootstrap'

interface AddedAlertProps {
  count: number
  show: boolean
  handleShowMessage: () => void
  request: number
}

export function AddedAlert (props: AddedAlertProps): JSX.Element {
  const { count, show, handleShowMessage, request } = props
  return (
    <Alert
      variant={count <= 30 ? 'success' : 'danger'}
      className="ml-auto mx-xl-auto fixed-bottom col-lg-4"
      show={show}
      onClose={handleShowMessage}
    >
      {count <= 30
        ? (
          <p className={'text-dark'}>Sample x {request} Added</p>
          )
        : (
          <p className={'text-dark'}>Cannot add more than 30 samples to your list</p>
          )}
    </Alert>
  )
}

export default AddedAlert
