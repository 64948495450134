
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import { FootNote } from 'components/FootNote'
import RegisterForm from 'components/RegisterForm'

export function RegisterPage (): JSX.Element {
  return (
    <CustomPageContainer>
      <div className="col-lg-6 ml-auto mr-auto">
        <RegisterForm />
      </div>
      <div className="mt-50">
        <FootNote />
      </div>
    </CustomPageContainer>
  )
}
