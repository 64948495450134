
import { useState, useEffect, useRef } from 'react'
import { ReactComponent as AddIcon } from 'assets/svg/list_add.svg'
import { ReactComponent as AddCheckedIcon } from 'assets/svg/list_add_check.svg'
import { IGroupedSampleItem, ISearchTablePaginProps } from 'contexts/SearchResultContext/SearchTableContext'
import {
  WithSampleListInputStateContext,
  WithSampleListActionContext
} from 'contexts/SampleListContext/WithSampleListContext'
import { ISampleListInputState, ISampleListAction } from 'contexts/SampleListContext'
import { WithSearchTablePaginContext } from 'contexts/SearchResultContext/SearchTableContext/WithSearchTableContext'
import { CustomIncreaseDecrease } from 'components/custom/CustomIncreaseDecrease'
import { NavLink } from 'react-bootstrap'
import TrialOverviewButton from 'components/TrialOverviewButton'
import AddedAlert from '../AddedAlert'
import VolumeWithTooltip from 'components/VolumeWithTooltip'

interface IGroupededRowProps extends IGroupedSampleItem, ISampleListInputState, ISampleListAction, ISearchTablePaginProps {
  isGroupedWithPrevious: boolean
  key: number
}

export function GroupededRow (props: IGroupededRowProps): JSX.Element {
  const { key, donorGroup, sampleType, volume, volumeUnit, trial, diagnosis, amount, savedSampleIds, sampleIds, isGroupedWithPrevious, handleAddClicked, currentPage } = props
  const [request, setRequest] = useState(1)
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(0)
  const isMounted = useRef(true)

  const handleShowMessage = (): void => {
    setCount(count + request)
    setShow(true)
    setTimeout(
      () => {
        isMounted.current && setShow(false)
      },
      count > 30 ? 4000 : 2000
    )
  }

  const handleAdd = (): void => {
    handleAddClicked(sampleIds, request)
    handleShowMessage()
  }

  const handleIncreaseClicked = (): void => {
    if (request < amount) {
      setRequest(request + 1)
    }
  }

  const handleDecreaseClicked = (): void => {
    if (request > 1) {
      setRequest(request - 1)
    }
  }

  useEffect(() => {
    (function resetRequest () {
      setRequest(1)
    })()
  }, [currentPage])

  useEffect(() => {
    (function isReachMax () {
      if (savedSampleIds.length <= 30) {
        setCount(savedSampleIds.length)
      }
    })()
  }, [savedSampleIds])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  const borderTop = (): string => {
    if (isGroupedWithPrevious || key === 0) {
      return 'border-top-0'
    } else {
      return ''
    }
  }

  return (
    <tr>
      <td className={`${borderTop()} p-4 align-middle text-center`}>{isGroupedWithPrevious ? '' : donorGroup}</td>
      <td className={`${borderTop()} p-4 align-middle`}>{trial?.id && <TrialOverviewButton trial={trial} />}</td>
      <td className={`${borderTop()} p-4 align-middle`}>{diagnosis?.desc}</td>
      <td className={`${borderTop()} p-4 align-middle`}>{sampleType?.desc}</td>
      <td className={`${borderTop()} p-4 align-middle text-center`}><VolumeWithTooltip volume={volume} unit={volumeUnit}/></td>
      <td className={`${borderTop()} p-4 align-middle text-center`}>{amount}</td>
      <td className={`${borderTop()} p-4 align-middle text-center`}>
        <CustomIncreaseDecrease
          number={request}
          handleIncreaseClicked={handleIncreaseClicked}
          handleDecreaseClicked={handleDecreaseClicked}
        />
      </td>
      <td className={`${borderTop()} p-4 align-middle text-center`}>
        {sampleType && !sampleIds.every((id) => savedSampleIds.includes(id))
          ? <NavLink
            onClick={handleAdd}
            data-toggle="tooltip"
            data-placement="left"
            title="Click to add the sample"
          >
            <AddIcon />
          </NavLink>
          : <div
            data-toggle="tooltip"
            data-placement="left"
            title="No more sample can be added"
          >
            <AddCheckedIcon />
          </div>
        }
      </td>
      <td className={`${borderTop()} p-4`} ><AddedAlert count={count} show={show} handleShowMessage={handleShowMessage} request={request} /></td>
    </tr>
  )
}

export default WithSearchTablePaginContext(
  WithSampleListInputStateContext(
    WithSampleListActionContext(
      GroupededRow
    )
  )
)
