import { memo } from 'react'

import { ISampleItem } from 'contexts/SearchResultContext'
import VolumeWithTooltip from 'components/VolumeWithTooltip'

export function FriendlyListRow (props: ISampleItem): JSX.Element {
  const { sampleId, donorGroup, sampleType, volume, volumeUnit, trial, diagnosis } = props

  return (
    <tr>
      <td className="align-middle text-center">{donorGroup && donorGroup}</td>
      <td className="align-middle">{trial?.id}</td>
      <td className="align-middle">{diagnosis?.desc}</td>
      <td className="align-middle">{sampleId && sampleId}</td>
      <td className="align-middle">{sampleType?.desc}</td>
      <td className="align-middle text-center"><VolumeWithTooltip volume={volume} unit={volumeUnit} /></td>
      <td className="align-middle text-center">{1}</td>
    </tr>
  )
}

export default memo(FriendlyListRow)
