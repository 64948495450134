import { ComponentType } from 'react'
import { NavContext, INavProps } from '.'

export function WithNavContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof INavProps>): JSX.Element {
    return (
      <NavContext.Consumer>{(contextProps) => <Component {...(props as T)} {...contextProps} />}</NavContext.Consumer>
    )
  }
}
