import CustomPageTitle from '../CustomPageTitle'

export interface ICustomFormProps {
  title?: string
  onSubmit: () => unknown
  children: React.ReactNode
}

export function CustomForm (props: ICustomFormProps): JSX.Element {
  const { title, onSubmit, children } = props
  return (
    <form className="p-5 rounded border shadow"
        style={{ minHeight: '35vh' }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => { e.preventDefault(); onSubmit() }}
    >
        <div className="d-flex justify-content-center">
            <CustomPageTitle title={title ?? ''} />
        </div>
      {children}
    </form>
  )
}
