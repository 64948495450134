import { IFilters } from 'contexts/FilterFacetContext'
import { ISearchResults } from 'contexts/SearchResultContext'
import { IUser, ILoginData, IRegisterData } from 'contexts/CurrentUserContext'
import { removeFromStorage, SESSIONSTORAGE } from 'util/storageHandler'
import { UnauthorizedError, ServerError, DataNotFoundError } from '../util/customErrors'

export interface IOrderSample {
  id?: string
  orderId?: string
  sampleId: string
}

export interface IOrder {
  id: string
  orderSamples: IOrderSample[]
  userId: string
  created: string
}

export interface IDataJson {
  helpPage: {
    message: string
  }

  contactPage: {
    message: string
  }

  myOrderPage: {
    downloadButton: {
      message: string
    }
  }

  sampleListPage: {
    top: {
      message: string
    }
  }

  searchPage: {
    top: {
      message: string
    }
    underSearchBox: {
      message: string
    }
  }

  volumeReminder: {
    message: string
  }

  groupByDonor: {
    message: string
  }
}

const AUTH = '/user/auth'

let baseUrl: string

if (process.env.NODE_ENV === 'development') {
  baseUrl = process.env.REACT_APP_BIOBANK_SEARCH_API_URL ?? ''
} else {
  baseUrl = `${window?.location?.origin}/api` ?? process.env.REACT_APP_BIOBANK_SEARCH_API_URL ?? ''
}

export const userApi = {
  register: async (registerData: IRegisterData): Promise<IUser> => await requests.post('/user/register', registerData),
  sendPasscode: async (): Promise<void> => await requests.get('/user/sendpasscode'),
  authenticate: async (): Promise<IUser> => await requests.get(AUTH),
  verify: async (passcode: string): Promise<IUser> => await requests.post('/user/verify', { passcode: passcode }),
  login: async (loginData: ILoginData): Promise<IUser> => await requests.post('/user/login', loginData),
  logout: async (): Promise<boolean> => await requests.delete('/user/logout')
}

export const sampleApi = {
  list: async (): Promise<IFilters> => await requests.get('/sample/types'),
  get: async (queryString: string): Promise<ISearchResults> => await requests.get(`/sample/samples?${queryString}`),
  getCount: async (queryString: string): Promise<number> => await requests.get(`/sample/samples/count?${queryString}`)
}

export const dataJsonApi = {
  get: async (): Promise<IDataJson> => await fetch('data.json').then(res => res.ok ? res.json() : '')
}

export const orderApi = {
  save: async (orderSamples: IOrderSample[]): Promise<IOrder> => await requests.post('/order/save', { orderSamples: orderSamples }),
  getUserOrders: async (): Promise<IOrder[]> => await requests.get('/order/userorders'),
  get: async (orderId: string): Promise<IOrder> => await requests.get(`/order/${orderId}`),
  delete: async (orderId: string): Promise<void> => await requests.delete(`/order/delete/${orderId}`)
}

const requests = {
  get: async (params: string) =>
    await fetch(`${baseUrl}${params}`, {
      credentials: 'include'
    })
      .then(async (res) => {
        if (res.status === 500) {
          throw new ServerError()
        } else if (res.status === 401) {
          removeFromStorage('user', SESSIONSTORAGE)
          if (params !== AUTH) {
            window.location.href = window.location.origin
          }
          throw new UnauthorizedError()
        }
        return await res.json()
      })
      .then((json) => {
        if (json.data !== null || json.data !== undefined) {
          return json.data.data ?? json.data
        } else {
          throw new DataNotFoundError()
        }
      })
      .catch((err) => {
        if (err.message) {
          throw err
        } else if (err.response) {
          throw new ServerError()
        } else {
          throw err
        }
      }),

  post: async (params: string, data: unknown) =>
    await fetch(`${baseUrl}${params}`, {
      credentials: 'include',
      method: 'post',
      body: JSON.stringify(data),
      headers: { 'Content-type': 'application/json' }
    })
      .then(async (res) => {
        if (res.status === 500) {
          throw new ServerError()
        } else if (res.status === 404) {
          throw new UnauthorizedError()
        } else if (res.status === 401) {
          removeFromStorage('user', SESSIONSTORAGE)
          throw new UnauthorizedError()
        }
        return await res.json()
      })
      .then((json) => {
        if (json.data) {
          return json.data.data ?? json.data
        } else {
          throw new DataNotFoundError()
        }
      })
      .catch((err) => {
        if (!err.response && !err.message) {
          throw new ServerError()
        } else {
          throw err
        }
      }),

  delete: async (params: string) =>
    await fetch(`${baseUrl}${params}`, {
      credentials: 'include',
      method: 'delete',
      headers: { 'Content-type': 'application/json' }
    })
      .then(async (res) => {
        if (res.status === 500) {
          throw new ServerError()
        } else if (res.status === 404) {
          throw new Error('Wrong inputs')
        } else if (res.status === 401) {
          removeFromStorage('user', SESSIONSTORAGE)
          throw new UnauthorizedError()
        }
        return await res.json()
      })
      .then((json) => {
        if (json.data) {
          return json.data.data ?? json.data
        } else {
          throw new DataNotFoundError()
        }
      })
      .catch((err) => {
        if (!err.response) {
          throw new ServerError()
        } else {
          throw err
        }
      })
}
