export function MyOrderTableHead (): JSX.Element {
  return (
    <thead>
      <tr>
        <th className='align-middle text-center p-2'>Donor Group</th>
        <th className='align-middle p-2'>Collection ID</th>
        <th className='align-middle p-2'>Diagnosis</th>
        <th className='align-middle p-2'>SampleID</th>
        <th className='align-middle p-2'>Sample Type</th>
        <th className='align-middle text-center p-2'>Volume</th>
        <th className='align-middle text-center p-3'>Request</th>
      </tr>
    </thead>
  )
}

export default MyOrderTableHead
