import { useState, useCallback } from 'react'
import { ReactComponent as ChevronUpIcon } from 'assets/svg/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron-down.svg'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { Collapse, NavLink } from 'react-bootstrap'
import FilterSubItem from './FilterSubItem'
import CustomLoadMore from 'components/custom/CustomLoadMore'
import { getSampleTypeNameToDisplay } from 'util/presentation'

interface IFilterItem {
  filterItemName: string
  filterSubItems: IFilterSubItem[]
}

export function FilterItem (props: IFilterItem): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { filterItemName, filterSubItems } = props

  // Slice large list
  const LIMIT = 15
  const [slicedFilterSubItems, setSlicedFilterSubItems] = useState<IFilterSubItem[]>(filterSubItems.slice(0, LIMIT))
  const [showMore, setShowMore] = useState(true)
  const [index, setIndex] = useState(LIMIT)

  const handleLoadMoreClicked = useCallback(() => {
    const newIndex = index + LIMIT
    const newShowMore = newIndex < filterSubItems.length - 2
    const newList = slicedFilterSubItems.concat(filterSubItems.slice(index, newIndex))
    setIndex(newIndex)
    setSlicedFilterSubItems(newList)
    setShowMore(newShowMore)
  }, [filterSubItems, index, setIndex, slicedFilterSubItems, setSlicedFilterSubItems, setShowMore])

  return (
    <div className="facet border-0 col-md-11">
      <div className="facet-heading" onClick={() => setIsCollapsed(!isCollapsed)}>
        <NavLink className="facet-title" data-toggle="collapse">
          {getSampleTypeNameToDisplay(filterItemName)}
          <br />({filterSubItems.length > 0 ? filterSubItems.length.toString() : '0'})
          {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </NavLink>
      </div>
      <Collapse in={isCollapsed}>
        <div className="facet-body p-0" id="facet-Type">
          <ul className="facet-items p-3">
            {slicedFilterSubItems.length > 0 &&
              slicedFilterSubItems.map((filterSubItem, key) => (
                <FilterSubItem key={key} filterSubItem={filterSubItem} filterItemName={filterItemName} />
              ))}
            <CustomLoadMore
              showMore={showMore}
              list={filterSubItems}
              index={index}
              handleLoadMoreClicked={handleLoadMoreClicked}
            />
          </ul>
        </div>
      </Collapse>
    </div>
  )
}

export default FilterItem
