
import { memo } from 'react'
import { ISampleItem } from 'contexts/SearchResultContext'
import { WithSampleListActionContext } from 'contexts/SampleListContext/WithSampleListContext'
import { ISampleListAction } from 'contexts/SampleListContext'
import { Spinner } from 'react-bootstrap'
import RemoveTableRowButton from 'components/RemoveTableRowButton'
import TrialOverviewButton from 'components/TrialOverviewButton'
import VolumeWithTooltip from 'components/VolumeWithTooltip'

interface INonGroupedSampleRowProps extends ISampleItem, ISampleListAction {
  isFetching: {
    processingIds: string[]
    isIncreasingOrDecreasing: boolean
    isRemoving: boolean
    isFetchingMore: boolean
  }
  isInitFetching: boolean
}
export function NonGroupedSampleRow (props: INonGroupedSampleRowProps): JSX.Element {
  const {
    donorGroup,
    sampleType,
    volume,
    volumeUnit,
    trial,
    diagnosis,
    handleRemoveClicked,
    sampleId,
    isFetching,
    isInitFetching
  } = props

  return (
    <>
      <tr>
        <td className="align-middle text-center p-4">{donorGroup && donorGroup}</td>
        <td className="align-middle p-4">{trial?.id && <TrialOverviewButton trial={trial} />}</td>
        <td className="align-middle p-4">{diagnosis?.desc}</td>
        <td className="align-middle p-4">{sampleType?.desc}</td>
        <td className="align-middle text-center p-4"><VolumeWithTooltip volume={volume} unit={volumeUnit} /></td>
        <td className="align-middle text-center p-4">{1}</td>
        <td className="align-middle text-center p-4">{1}</td>
        {isFetching.isRemoving && isFetching.processingIds[0] === sampleId
          ? (
            <td className="align-middle text-center p-5">
              <Spinner animation="border" />
            </td>
            )
          : (
            <td className="align-middle text-center p-5">
              {sampleType?.id && (
                <RemoveTableRowButton
                  message={'Delete this sample? Are you sure?'}
                  isFetching={isFetching.isIncreasingOrDecreasing || isFetching.isRemoving || isInitFetching}
                  handleRemoveClicked={() => handleRemoveClicked([sampleId], [sampleId])}
                />
              )}
            </td>
            )}
      </tr>
    </>
  )
}

export default WithSampleListActionContext(memo(NonGroupedSampleRow))
