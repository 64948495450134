
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg'
import { WithMenuContext } from 'contexts/HeaderContext/MenuContext/WithMenuContext'
import { IMenuProps } from 'contexts/HeaderContext/MenuContext'

function MenuButton (props: IMenuProps): JSX.Element {
  const { isMenuOpen, handleMenuOpenClose } = props
  return (
    <div className="nav-global-menu d-lg-none">
      <div
        onClick={handleMenuOpenClose}
        className={`nav-global-btn d-flex d-lg-none ${isMenuOpen ? 'menu-open' : ''}`}
        role="button"
        id="openNavigation"
      >
        <MenuIcon />
        <span className="d-xs-none" id="openNavigationText">
          {isMenuOpen ? 'Close' : 'Menu'}
        </span>
      </div>
    </div>
  )
}

export default WithMenuContext(MenuButton)
