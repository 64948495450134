import { useState, useEffect } from 'react'
import { CustomPageContainer } from 'components/custom/CustomPageContainer'
import { Card } from 'react-bootstrap'
import CustomPageTitle from 'components/custom/CustomPageTitle'
import { dataJsonApi } from 'api/api'

export function HelpPage (): JSX.Element {
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setMessage(json.helpPage.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <CustomPageContainer>
      <CustomPageTitle title="Help" />
      <Card className="w-100 text-center d-flex flex-column align-items-center">
        <Card.Body className="w-50">
          <Card.Text>
            {message}
          </Card.Text>
        </Card.Body>
      </Card>
    </CustomPageContainer>
  )
}
