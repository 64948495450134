/* eslint-disable max-len */

import ref2014 from 'assets/png/ref-2014.png'
import qaaLogo from 'assets/png/qaa-logo.png'
import russellGroup from 'assets/png/russell-group.png'

export function Footer (): JSX.Element {
  return (
    <footer className="footer-global">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-2">
            <h2 className="footer-section-title">The University</h2>
            <ul className="list list-links">
              <li>
                <a
                  href="https://www.cardiff.ac.uk/about/organisation/college-structure"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Academic Schools"
                  aria-label="Academic Schools"
                >
                  Academic Schools
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/visit"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Visit us"
                  aria-label="Visit the University"
                >
                  Visit us
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/contact-us"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Contact us"
                  aria-label="Contact the University"
                >
                  Contact us
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/contact-us/security"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Emergency contacts"
                  aria-label="Emergency contacts"
                >
                  Emergency contacts
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/public-information/"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Public information"
                  aria-label="Public information"
                >
                  Public information
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2">
            <h2 className="footer-section-title">Our facilities</h2>
            <ul className="list list-links">
              <li>
                <a
                  href="https://www.cardiff.ac.uk/libraries"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Libraries"
                  aria-label="Libraries at Cardiff University"
                >

                  Libraries
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/conferences"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Conferences"
                  aria-label="Conferences at the University"
                >
                  Conferences
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/research-equipment/"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Research equipment"
                  aria-label="Research equipment at the University"
                >
                  Research equipment
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/sport"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Sport"
                  aria-label="Sport at the University"
                >
                  Sport
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2">
            <h2 className="footer-section-title">Connect with us</h2>
            <ul className="list list-links">
              <li>
                <a
                  href="https://www.cardiff.ac.uk/new-students"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="New students"
                  aria-label="New students"
                >
                  New students
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/alumni"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Alumni"
                  aria-label="Alumni of the University"
                >
                  Alumni
                </a>
              </li>
              <li>
                <a
                  href="https://blogs.cardiff.ac.uk/"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Blogs"
                  aria-label="Blogs from Cardiff University"
                >
                  Blogs
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2">
            <h2 className="footer-section-title">Quick links</h2>
            <ul className="list list-links">
              <li>
                <a
                  href="https://www.cardiff.ac.uk/jobs"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Job opportunities"
                  aria-label="All job opportunities"
                >
                  Job opportunities
                </a>
              </li>
              <li>
                <a
                  href="https://intranet.cardiff.ac.uk"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Intranet"
                  aria-label="Intranet for staff and students"
                >
                  Intranet
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/media"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Media centre"
                  aria-label="Media centre"
                >
                  Media centre
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/people"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="People"
                  aria-label="People at Cardiff University"
                >
                  People
                </a>
              </li>
              <li>
                <a
                  href="https://research.cardiff.ac.uk/"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Research Portal"
                  aria-label="Research Portal"
                >
                  Research Portal
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4">
            <ul className="list list-horizontal list-icons footer-global-icons icons-md no-padding">
              <li>
                <a
                  href="https://www.facebook.com/cardiffuni"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Facebook"
                >
                  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                    <title>facebook</title>

                    <path
                      className="icon-background"
                      d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z"
                    ></path>
                    <path
                      className="icon-foreground"
                      d="M711.3,660,734,512H592V416c0-40.49,19.84-80,83.44-80H740V210s-58.59-10-114.61-10C508.44,200,432,270.88,432,399.2V512H302V660H432v357.78a517.58,517.58,0,0,0,160,0V660Z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/cardiffuni"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Twitter"
                >
                  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 325.04">
                    <title>twitter no background icon</title>
                    <path
                      d="M400,75.41v.39c-10.55,4.69-33.2,12.5-47.27,12.5,16.8-7.81,30.47-26.95,36.33-44.92-13.67,7.81-34,18-52.34,19.53-7.42-7.42-16.41-14.45-26.56-18.75s-21.1-7-33.21-6.64c-23-.39-43,9.37-57.81,23.83a81.48,81.48,0,0,0-24.22,57.81,95.51,95.51,0,0,0,2,19.14c-34-2-66.41-10.55-94.93-25.78S48.05,77.36,27.73,52C21.09,65.64,16.8,78.53,16.8,93.77c0,13.67,3.51,27.73,10.15,39.06,6.25,11.33,15.24,22.27,26.17,28.91-7.42,0-26.56-3.52-37.1-9.77v.39a81.17,81.17,0,0,0,19.14,52.35C46.88,219.16,63.28,228.92,82,233.22c-17.19,4.69-25.78,3.52-37.11,1.56A83.09,83.09,0,0,0,73.83,275c13.28,9.76,30.08,16.4,47.65,16.4C107.42,302.75,91.41,311,74.61,317.21c-17.19,6.25-35.94,9.37-55.08,9.76-3.51,0-16-.78-19.53-1.56,18.36,12.11,38.28,20.7,59.38,26.95,21.09,6.64,43.35,9.77,66.4,10.16,75.39-.39,134-31.25,173.44-76.56,39.45-44.93,60.16-102.74,60.16-157.43,0-5.07-.4-8.2-.4-10.15,8.21-6.25,15.63-12.11,22.66-19.53A157.77,157.77,0,0,0,400,75.41Z"
                      transform="translate(0 -37.48)"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/cardiffuni"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Instagram"
                >
                  <svg className="icon" viewBox="0 0 503.84 503.84">
                    <title>instagram</title>
                    <path
                      d="M256,49.47c67.27,0,75.23.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67a78,78,0,0,1,29,18.85,78,78,0,0,1,18.85,29c3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.57,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78a83.51,83.51,0,0,1-47.81,47.81c-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78,78,0,0,1-29-18.85,78,78,0,0,1-18.85-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78a78,78,0,0,1,18.85-29,78,78,0,0,1,29-18.85c8.88-3.45,22.22-7.55,46.78-8.67,26.57-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52S107,11.08,91,17.3A123.68,123.68,0,0,0,46.36,46.36,123.68,123.68,0,0,0,17.3,91c-6.22,16-10.48,34.34-11.7,61.15S4.08,187.58,4.08,256s.29,77,1.52,103.87S11.08,405,17.3,421a123.68,123.68,0,0,0,29.06,44.62A123.52,123.52,0,0,0,91,494.69c16,6.23,34.34,10.49,61.15,11.71s35.45,1.52,103.87,1.52,77-.29,103.87-1.52S405,500.92,421,494.69A128.74,128.74,0,0,0,494.69,421c6.23-16,10.49-34.34,11.71-61.15s1.52-35.45,1.52-103.87-.29-77-1.52-103.87S500.92,107,494.69,91a123.52,123.52,0,0,0-29.05-44.62A123.68,123.68,0,0,0,421,17.3c-16-6.22-34.34-10.48-61.15-11.7S324.42,4.08,256,4.08Z"
                      transform="translate(-4.08 -4.08)"
                    />
                    <path
                      d="M256,126.64A129.36,129.36,0,1,0,385.36,256,129.35,129.35,0,0,0,256,126.64ZM256,340a84,84,0,1,1,84-84A84,84,0,0,1,256,340Z"
                      transform="translate(-4.08 -4.08)"
                    />
                    <circle cx="386.4" cy="117.44" r="30.23" />
                  </svg>{' '}
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/cardiffuni"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="YouTube"
                >
                  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.97 24">
                    <title>Youtube</title>
                    <path
                      d="M26,4l0,0a6,6,0,0,1,6,6V22a6,6,0,0,1-6,6H6a6,6,0,0,1-6-6V10A5.88,5.88,0,0,1,1.78,5.75,5.9,5.9,0,0,1,6,4H26ZM22,16L12,8V24Z"
                      transform="translate(0 -4)"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ul className="list list-links footer-global-logos">
              <li>
                <a href="www.cardiff.ac.uk/research/impact-and-innovation/quality-and-performance/ref-2014">
                  <img
                    className="lazy footer-global-logos-image"
                    src={ref2014}
                    data-src="https://cardiff.imgix.net/__data/assets/image/0008/946529/ref-2014.png?q=60&auto=format"
                    alt="REF 2014 - Research Excellence Framework. Ranked 5th in the UK"
                  />
                </a>
              </li>
              <li>
                <a href="http://www.qaa.ac.uk/reviews-and-reports/provider?UKPRN=10007814">
                  <img
                    className="lazy footer-global-logos-image"
                    src={qaaLogo}
                    data-src="https://cardiff.imgix.net/__data/assets/image/0005/946562/qaa-logo.png?q=60&auto=format"
                    alt="QAA checks how UK universities, colleges and other providers maintain the standard of their higher education provision. Read this institution's latest review report. The QAA diamond logo and 'QAA' are registered trademarks of the Quality Assurance Agency for Higher Education."
                  />
                </a>
              </li>
              <li>
                <a href="https://russellgroup.ac.uk/about/our-universities/cardiff-university/">
                  <img
                    className="lazy footer-global-logos-image"
                    src={russellGroup}
                    data-src="https://cardiff.imgix.net/__data/assets/image/0009/946530/russell-group.png?q=60&auto=format"
                    alt="Russell Group"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <p>Copyright Cardiff University. Registered charity no. 1136855</p>
          </div>
          <div className="col-sm-12 col-md-6">
            <ul className="list list-horizontal footer-global-legal float-right">
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/accessibility"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Accessibility"
                  aria-label="Website accessibility"
                >
                  Accessibility
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/public-information/corporate-information/modern-slavery"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Modern Slavery statement"
                  aria-label="Modern slavery statement"
                >
                  Modern slavery statement
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/privacy"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Privacy"
                  aria-label="Website privacy notice"
                >
                  Privacy
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/termsofuse"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Terms of use"
                  aria-label="Terms of use"
                >
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  href="https://www.cardiff.ac.uk/help/cookies"
                  className="ga-event"
                  data-action="click"
                  data-category="Public global footer"
                  data-label="Cookies"
                  aria-label="Website cookies notice"
                >
                  Cookies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
