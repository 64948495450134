import { ComponentType } from 'react'
import { FilterFacetStateContext, IFilterFacetState, IFilterModalProps, FilterModalContext } from '.'

export function WithFilterFacetStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof IFilterFacetState>): JSX.Element {
    return (
      <FilterFacetStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </FilterFacetStateContext.Consumer>
    )
  }
}

export function WithIFilterModalContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof IFilterModalProps>): JSX.Element {
    return (
      <FilterModalContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </FilterModalContext.Consumer>
    )
  }
}
