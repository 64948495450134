import { useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { WithMenuContext } from 'contexts/HeaderContext/MenuContext/WithMenuContext'
import { IMenuProps } from 'contexts/HeaderContext/MenuContext'
import styles from './index.module.scss'
import { INavProps } from 'contexts/HeaderContext/NavContext'
import { WithNavContext } from 'contexts/HeaderContext/NavContext/WithNavContext'

interface IGlobalNavMobileProps extends IMenuProps, INavProps {}

function GlobalNavMobile (props: IGlobalNavMobileProps): JSX.Element {
  const history = useHistory()
  const { routes, secondaryRoutes, handleMenuOpenClose } = props
  const combineRoutes = useMemo(() => [...routes, ...secondaryRoutes], [routes, secondaryRoutes])
  return (
    <nav className={`nav-global-mobile ${styles.NavClass} `}>
      <div className="nav-global-mobile-previous" id="mobileNavigationItems">
        {combineRoutes.map((route, key) =>
          history.location.pathname === route.href
            ? (
            <li key={key} className="nav-item nav-global-mobile-section">
              <NavLink
                to={route.href}
                data-assetid="104479"
                onClick={handleMenuOpenClose}
                className={'nav-link active'}
              >
                {route.title}
              </NavLink>
            </li>
              )
            : (
            <li key={key} className="nav-item">
              <NavLink to={route.href} onClick={handleMenuOpenClose} className="nav-link">
                {route.title}
              </NavLink>
            </li>
              )
        )}
      </div>
    </nav>
  )
}

export default WithMenuContext(WithNavContext(GlobalNavMobile))
