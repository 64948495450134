
export interface ICustomIncreaseDecreaseProps {
  number: number
  handleIncreaseClicked: () => void
  handleDecreaseClicked: () => void
}

export function CustomIncreaseDecrease (props: ICustomIncreaseDecreaseProps): JSX.Element {
  const { number, handleIncreaseClicked, handleDecreaseClicked } = props
  return (
    <div className="d-flex">
      <span className="pr-10" onClick={handleDecreaseClicked} role="button">
        &#10094;
      </span>
      {number}
      <span className="pl-10" onClick={handleIncreaseClicked} role="button">
        &#10095;
      </span>
    </div>
  )
}
