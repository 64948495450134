import { ISearchResults } from 'contexts/SearchResultContext'
import { IFiltersToDisplay } from 'contexts/FilterFacetContext'
import { IUser } from 'contexts/CurrentUserContext'
import { ICustomModalOptions } from 'contexts/CustomModalContext'

export const emptyCurrentUser: IUser = { id: '', email: '', username: '', name: '', active: false }
export const emptyFilters: IFiltersToDisplay = {
  sampleType: [],
  tissueType: [],
  gender: [],
  ageRange: [],
  storageType: [],
  consentRestrictions: [],
  diagnosis: [],
  metastasis: [],
  trial: []
}

export const emptySearchResult: ISearchResults = {
  samples: []
}

export const defaultModalOptions: ICustomModalOptions = {
  title: '',
  Body: '',
  show: false,
  handleShowClose: () => {

  },
  handleConfirm: undefined,
  variant: 'primary',
  isAnimation: true
}
