import { useState, useEffect, useCallback } from 'react'
import { NavLink } from 'react-bootstrap'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { WithCustomModalContext } from 'contexts/CustomModalContext/WithCustomModalContext'
import { ICustomModalProps } from 'contexts/CustomModalContext'

export interface ITrialOverviewButtonProps extends ICustomModalProps {
  trial: IFilterSubItem
}

export function TrialOverviewButton (props: ITrialOverviewButtonProps): JSX.Element {
  const { trial, changeOptions } = props
  const [show, setShow] = useState(false)
  const handleShowClose = useCallback(() => {
    setShow(!show)
  }, [show])

  useEffect(() => {
    changeOptions({ title: `Overview of ${trial.id}`, Body: trial.notes, show: show, handleShowClose: handleShowClose })
  }, [changeOptions, handleShowClose, show, trial])

  return (
    <NavLink
      data-toggle="tooltip"
      data-placement="top"
      title="Open a dialogue to view the detail of this collection"
      className="ml-0 pl-0"
      onClick={handleShowClose}
    >
      {trial?.id}
    </NavLink>
  )
}

export default WithCustomModalContext(TrialOverviewButton)
