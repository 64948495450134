
import { Table } from 'react-bootstrap'
import SampleListBody from './SampleListBody'
import { ISampleListOptionState } from 'contexts/SampleListContext'
import { WithSampleListOptionStateContext } from 'contexts/SampleListContext/WithSampleListContext'
import SampleListHead from './SampleListHead'

interface ISampleListTableProps extends ISampleListOptionState {
}

export function SampleListTable (props: ISampleListTableProps): JSX.Element {
  const { groupByDonor } = props

  return (
    <div className="row">
      <div className="col-md-12">
        <Table id="table" className="border-0" responsive="lg">
          <SampleListHead />
          <SampleListBody groupByDonor={groupByDonor} />
        </Table>
      </div>
    </div>
  )
}

export default WithSampleListOptionStateContext(SampleListTable)
