import { useEffect, useState, useCallback, useRef } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { ISampleListAction, ISampleListInputState, ISampleListFetchingState } from 'contexts/SampleListContext'
import { useHistory } from 'react-router-dom'
import { WithCustomModalContext } from 'contexts/CustomModalContext/WithCustomModalContext'
import { ICustomModalProps } from 'contexts/CustomModalContext'
import { orderApi } from 'api/api'
import { WithSampleListInputStateContext, WithSampleListActionContext, WithSampleListFetchingStateContext } from 'contexts/SampleListContext/WithSampleListContext'

interface ICheckoutButtonProps extends ISampleListAction, ISampleListInputState, ICustomModalProps, ISampleListFetchingState {
}

export function CheckoutButton (props: ICheckoutButtonProps): JSX.Element {
  const { changeOptions, options, savedSampleIds, handleRemoveClicked, isInitFetching, isOtherFetching } = props
  const history = useHistory()
  const [isCheckingout, setIsCheckingout] = useState(false)
  const [show, setShow] = useState(false)
  const isMounted = useRef(true)
  const handleShowClose = useCallback(() => {
    setShow(!show)
  }, [show])

  const handleConfirmCheckout = useCallback(async () => {
    changeOptions({
      ...options,
      show: false
    })

    setIsCheckingout(true)
    const order = await orderApi.save(savedSampleIds.map((id) => { return { sampleId: id } })).catch(() => {
      if (isMounted.current) {
        alert('Something went wrong when saving the order')
        setIsCheckingout(false)
      }
    })
    if (order && isMounted.current) {
      setIsCheckingout(false)
      handleRemoveClicked(savedSampleIds, savedSampleIds)
      history.push(`/myorder/${order.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRemoveClicked, savedSampleIds, history, changeOptions])

  useEffect(() => {
    changeOptions({
      title: 'Do you want to checkout?',
      Body: 'When checking out your sample list will be cleared. A record of your previous requests can be found under the My Orders tab.',
      show: show,
      handleShowClose: handleShowClose,
      handleConfirm: handleConfirmCheckout
    })
  }, [changeOptions, handleShowClose, handleConfirmCheckout, show, history])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
      changeOptions({
        title: '',
        Body: '',
        show: false,
        handleShowClose: () => {}
      })
    }
  }, [changeOptions])

  const isDisable =
    isCheckingout ||
    savedSampleIds.length < 1 ||
    isInitFetching ||
    isOtherFetching.isIncreasingOrDecreasing ||
    isOtherFetching.isRemoving ||
    isOtherFetching.isFetchingMore

  return (
    <Button disabled={ isDisable } onClick={handleShowClose}>
        {isCheckingout
          ? (
          <>
            <Spinner size="sm" animation="border" variant="light" />
            <span className="ml-5">Checking out...</span>
          </>
            )
          : <div>Checkout&rarr;</div> }
    </Button>
  )
}

export default WithSampleListActionContext(
  WithSampleListInputStateContext(
    WithCustomModalContext(
      WithSampleListFetchingStateContext(
        CheckoutButton
      )
    )
  )
)
