
import { memo, useState, useEffect, useCallback } from 'react'
import { Pagination, Spinner, Alert } from 'react-bootstrap'
import { ReactComponent as PaginationLeftIcon } from 'assets/svg/pagination-left.svg'
import { ReactComponent as PaginationRightIcon } from 'assets/svg/pagination-right.svg'

export interface ICustomPaginationProps {
  changePage: (pageNumber: number) => void
  currentPage: number
  allPageNumbers: number[]
  handleLoadMore?: () => void
  isLoadMoreFetching?: boolean
  isLoadMoreNotFound?: boolean
}

export function CustomPagination (props: ICustomPaginationProps): JSX.Element {
  const { changePage, currentPage, handleLoadMore, isLoadMoreFetching, allPageNumbers, isLoadMoreNotFound } = props
  const [paginationsShowing, setPaginationsShowing] = useState<number[]>([])
  // Set dots in paginationsShowing
  const dotsInitial = 0.1
  const dotsLeft = 0.2
  const dotsRight = 0.3
  // Set JUMP to skip more or less page when clicking dots
  const JUMP = 3
  // Set MAX to show more or less pagminations
  const MAX = 5
  // Set SLICESTART and SLICEEND to change the range of pagination slice
  const SLICESTART = currentPage - 2
  const SLICEEND = currentPage + 1

  const handlePaginateRight = useCallback(() => {
    if (currentPage < allPageNumbers.length) {
      changePage(currentPage + 1)
    } else if (handleLoadMore) {
      handleLoadMore()
    }
  }, [allPageNumbers.length, changePage, currentPage, handleLoadMore])

  const handleEllipsisClick = useCallback(
    (pageNumber: number) => {
      if (pageNumber === dotsInitial) {
        changePage(paginationsShowing[paginationsShowing.length - 3] + 1)
      } else if (pageNumber === dotsLeft) {
        changePage(paginationsShowing[3] - JUMP)
      } else if (pageNumber === dotsRight) {
        changePage(paginationsShowing[3] + JUMP)
      }
    },
    [changePage, paginationsShowing]
  )

  // If current page or allPageNumbers changed, change the paginations which are showing
  useEffect(() => {
    (function handlePagesShowing () {
      let tempPageNumbers = [...allPageNumbers]
      if (allPageNumbers.length < MAX + 1) {
        tempPageNumbers = allPageNumbers
      } else if (currentPage >= 1 && currentPage <= MAX) {
        const sliced = allPageNumbers.slice(0, MAX)
        tempPageNumbers = [...sliced, dotsInitial, allPageNumbers.length]
      } else if (currentPage > MAX && currentPage < allPageNumbers.length - 2) {
        const sliced1 = allPageNumbers.slice(SLICESTART, currentPage)
        const sliced2 = allPageNumbers.slice(currentPage, SLICEEND)
        tempPageNumbers = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, allPageNumbers.length]
      } else if (currentPage > allPageNumbers.length - MAX) {
        const sliced = allPageNumbers.slice(allPageNumbers.length - (MAX - 1))
        tempPageNumbers = [1, dotsLeft, ...sliced]
      }
      setPaginationsShowing(tempPageNumbers)
    })()
  }, [currentPage, allPageNumbers, handleLoadMore, SLICESTART, SLICEEND])

  useEffect(() => {
    if (handleLoadMore && currentPage === allPageNumbers[allPageNumbers.length - 1] && currentPage >= 1) {
      handleLoadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, allPageNumbers])

  useEffect(() => {
    (function resetPage () {
      if (currentPage > allPageNumbers[allPageNumbers.length - 1] || currentPage < allPageNumbers[0]) {
        changePage(1)
      }
    })()
  }, [currentPage, allPageNumbers, changePage])

  return (
    <div className="d-flex justify-content-center flex-column flex-xl-row align-items-center mt-10 pl-xl-40">
      {allPageNumbers.length > 0 &&
        <Pagination className="d-flex justify-content-center mb-xl-0">
          <Pagination.Item onClick={() => changePage(currentPage > 1 ? currentPage - 1 : currentPage)}>
            <PaginationLeftIcon />
          </Pagination.Item>
          {paginationsShowing.map((number) =>
            number === dotsInitial || number === dotsLeft || number === dotsRight
              ? <Pagination.Ellipsis key={number} onClick={() => handleEllipsisClick(number)} />
              : <Pagination.Item active={number === currentPage} onClick={() => changePage(number)} key={number}>
                {number}
              </Pagination.Item>
          )}
          <Pagination.Item onClick={handlePaginateRight}>
            <PaginationRightIcon />
          </Pagination.Item>
        </Pagination>
      }
      {allPageNumbers.length > 0 && isLoadMoreFetching === true
        ? <Spinner className="ml-15" animation="border" variant="danger" />
        : isLoadMoreNotFound &&
        <Alert className="ml-15 mt-15" variant={'warning'}>
          No more results can be loaded
        </Alert>
      }
    </div>
  )
}

export default memo(CustomPagination)
