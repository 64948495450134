import { Fragment } from 'react'
import SelectedFacet from './SelectedFacet'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import {
  WithSearchActionContext,
  WithSearchInputStateContext
} from 'contexts/SearchResultContext/WithSearchResultContext'
import { ISearchInputState, ISearchAction } from 'contexts/SearchResultContext'

interface ISelectedFacetsProps extends ISearchInputState, ISearchAction { }

export function SelectedFacets (props: ISelectedFacetsProps): JSX.Element {
  const { filtersToFetch, handleFilterClicked } = props

  return (
    <ul className="selected-facets">
      {Object.keys(filtersToFetch).map((filterItemName, key) => {
        const filterSubItems: IFilterSubItem[] = filtersToFetch[filterItemName]
        return (
          <Fragment key={key}>
            {filterSubItems.map((filterSubItem, key) => {
              return <SelectedFacet
                key={key}
                handleFilterClicked={handleFilterClicked}
                filterItemName={filterItemName}
                filterSubItem={filterSubItem}
              />
            })}
          </Fragment>
        )
      })}
    </ul>
  )
}

export default WithSearchActionContext(WithSearchInputStateContext(SelectedFacets))
