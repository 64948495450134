
import { Route, Redirect } from 'react-router-dom'
import { ICurrentUserState } from 'contexts/CurrentUserContext'
import { WithCurrentUserStateContext } from 'contexts/CurrentUserContext/WithCurrentUserContext'
import { RegisterPage } from 'pages/RegisterPage'

interface IRegisterRouteProps extends ICurrentUserState {
  children: React.ReactNode
  [x: string]: unknown
}

function RegisterRoute (props: IRegisterRouteProps): JSX.Element {
  const { currentUser, children, ...rest } = props
  return (
    <Route {...rest}>
      {!currentUser.id && !currentUser.active
        ? <RegisterPage />
        : <Redirect to={{
          pathname: '/verify'
        }} />
      }
    </Route>
  )
}

export default WithCurrentUserStateContext(RegisterRoute)
