import { ISampleItem, ISearchResults } from 'contexts/SearchResultContext'
import { IGroupedSampleItem } from 'contexts/SearchResultContext/SearchTableContext'
import { ISavedGroupedSampleItem } from 'contexts/SampleListContext'
import { getSameGroupSamplesQueryStringGenerator } from './queryStringGenerator'
import { sampleApi } from 'api/api'

// Group results
interface IGroupedSampleItems {
  [GroupedSampleItemKey: string]: IGroupedSampleItem
}

export const groupSamples = (data: ISampleItem[], groupByDonor: boolean): IGroupedSampleItems => {
  return data.reduce((acc: IGroupedSampleItems, sample: ISampleItem) => {
    const { sampleId, donorGroup, sampleType, volume, diagnosis, trial, volumeUnit } = sample

    // accKey will be used by reduce function to CHECK if every field is the same]
    let accKey = sampleType.id
    if (groupByDonor && volume && volumeUnit && diagnosis) {
      accKey = sampleType.id + donorGroup + volume.id + volumeUnit.id + trial.id + diagnosis.id
    }

    if (!acc[accKey]) {
      acc[accKey] = {
        donorGroup: groupByDonor ? donorGroup : '',
        sampleType: sampleType,
        volume: volume,
        volumeUnit: volumeUnit,
        amount: 1,
        trial: trial,
        diagnosis: diagnosis,
        sampleIds: [sampleId]
      }
      return acc
    }
    acc[accKey].sampleIds.push(sampleId)
    acc[accKey].amount += 1
    return acc
  }, {})
}

export const groupSavedSamples = async (
  savedSampleList: ISearchResults,
  savedGroupedSampleList: ISavedGroupedSampleItem[],
  groupByDonor: boolean
): Promise<ISavedGroupedSampleItem[]> => {
  const computed = Object.entries(groupSamples([...savedSampleList.samples], groupByDonor))
  let groupedSamples: ISavedGroupedSampleItem[]
  try {
    groupedSamples = await Promise.all(
      computed.map(async (i) => {
        let addedExtraProperties: ISavedGroupedSampleItem
        const sameGroupSameples = savedGroupedSampleList.find((sample) =>
          sample.allSameGroupSampleIds.some((id) => i[1].sampleIds.includes(id))
        )
        if (
          !sameGroupSameples ||
          !sameGroupSameples.available ||
          sameGroupSameples.available === 0 ||
          sameGroupSameples.allSameGroupSampleIds.length < 1
        ) {
          const fetchedsameGroupSameples = await sampleApi.get(getSameGroupSamplesQueryStringGenerator(i[1])).catch(
            () => {}
          )
          addedExtraProperties = {
            ...i[1],
            available: fetchedsameGroupSameples ? fetchedsameGroupSameples.samples.length : 0,
            allSameGroupSampleIds: fetchedsameGroupSameples
              ? fetchedsameGroupSameples.samples.map((fetchedsameGroupSameple) => fetchedsameGroupSameple.sampleId)
              : []
          }
          return addedExtraProperties
        }
        addedExtraProperties = {
          ...i[1],
          available: sameGroupSameples ? sameGroupSameples.available : 0,
          allSameGroupSampleIds: sameGroupSameples ? sameGroupSameples.allSameGroupSampleIds : []
        }
        return addedExtraProperties
      })
    )
  } catch {
    groupedSamples = computed.map((i) => {
      const addedExtraProperties = { ...i[1], available: 0, allSameGroupSampleIds: [] }
      return addedExtraProperties
    })
  }

  return groupedSamples
}

// export const generateRandomDonorGroup = (samples: Array<ISampleItem | ISavedGroupedSampleItem>): unknown => {
//   const seen: { [key: string]: string } = {}
//   let index = 1

//   return samples.map((sample: ISampleItem | ISavedGroupedSampleItem) => {
//     if (!seen[sample.donorGroup]) {
//       seen[sample.donorGroup] = index.toString()
//       index = index + 1
//     }
//     return { ...sample, donorGroup: seen[sample.donorGroup] }
//   })
// }
