import { createContext } from 'react'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { ISampleItem } from 'contexts/SearchResultContext'

export interface IGroupedSampleItem {
  sampleType: IFilterSubItem
  volume: IFilterSubItem
  volumeUnit: IFilterSubItem
  amount: number
  trial: IFilterSubItem
  diagnosis: IFilterSubItem
  sampleIds: string[]
  donorGroup: string
}

export interface IComputedSearchOutputState {
  slicedSearchResults: ISampleItem[]
  groupedSearchResults: IGroupedSampleItem[]
}

export interface ISearchTableToggleProps {
  groupByDonor: boolean
  handleToggleClicked: () => void
}

export interface ISearchTablePaginProps {
  currentPage: number
  allPageNumbers: number[]
  changeTablePage: (number: number) => void
}

export const ComputedSearchOutputStateContext = createContext<IComputedSearchOutputState>({
  slicedSearchResults: [],
  groupedSearchResults: []
})

export const SearchTableToggleContext = createContext<ISearchTableToggleProps>({
  groupByDonor: false,
  handleToggleClicked: () => {

  }
})

export const SearchTablePaginContext = createContext<ISearchTablePaginProps>({
  currentPage: 1,
  allPageNumbers: [0],
  changeTablePage: () => {

  }
})
