import { useState, FC, useEffect, useCallback, useRef } from 'react'
import { CurrentUserStateContext, CurrentUserActionContext, IUser } from '.'
import PropTypes from 'prop-types'
import { userApi } from 'api/api'
import { SESSIONSTORAGE, getFromStorage, saveToStorage } from 'util/storageHandler'
import { useHistory } from 'react-router-dom'
import { emptyCurrentUser } from 'data'

export const CurrentUserContextProvider: FC = ({ children }) => {
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState<IUser>(emptyCurrentUser)
  const isMounted = useRef(true)

  const changeCurrentUser = useCallback((user: IUser) => {
    setCurrentUser(user)
  }, [])

  useEffect(() => {
    (async function authenticate (): Promise<void> {
      const storedUser: IUser = JSON.parse(getFromStorage('user', SESSIONSTORAGE))
      if (storedUser) {
        storedUser.active = JSON.parse(storedUser.active.toString())
        setCurrentUser(storedUser)
      } else {
        const newUser = await userApi.authenticate()
        if (newUser && isMounted.current) {
          setCurrentUser(newUser)
          saveToStorage('user', JSON.stringify(newUser), SESSIONSTORAGE)
        }
      }
    })().catch(err => err)
  }, [])

  useEffect(() => {
    return history.listen((location) => {
      (async function logout (): Promise<void> {
        if (location.pathname === '/logout') {
          setCurrentUser(emptyCurrentUser)
          sessionStorage?.clear()
          await userApi.logout()
          window.location.href = process.env.REACT_APP_BASE_URL ?? '/'
        }
      })().catch(err => err)
    })
  }, [history])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [history])

  return (
    <CurrentUserStateContext.Provider value={{ currentUser }}>
      <CurrentUserActionContext.Provider value={{ changeCurrentUser }}>{children}</CurrentUserActionContext.Provider>
    </CurrentUserStateContext.Provider>
  )
}

CurrentUserContextProvider.propTypes = {
  children: PropTypes.node
}
