import { useEffect, useState, useCallback, useRef } from 'react'
import TableLoadingSpinner from 'components/TableLoadingSpinner'
import { orderApi, IOrder } from 'api/api'
import { Alert } from 'react-bootstrap'
import { OrderRow } from '../OrderRow'

export function MyOrdersTableBody (): JSX.Element {
  const [isFetching, setIsFetching] = useState(false)
  const [isNotFound, setIsNotFound] = useState({
    isNotFound: false,
    message: ''
  })
  const isMounted = useRef(true)
  const [orders, setOrders] = useState<IOrder[]>([])

  const getOrders = useCallback(async (): Promise<void> => {
    setOrders([])
    setIsFetching(true)
    const ordersData = await orderApi.getUserOrders()
    if (isMounted.current) {
      if (ordersData) {
        setIsNotFound({ isNotFound: false, message: '' })
        setOrders(ordersData)
      }
      setIsFetching(false)
    }
  }, [])

  const handleGetOrders = useCallback(() => {
    getOrders().catch(() => {
      if (isMounted.current) {
        setIsFetching(false)
        setIsNotFound({ isNotFound: true, message: 'No order found' })
      }
    })
  }, [getOrders])

  useEffect(() => {
    handleGetOrders()
  }, [handleGetOrders])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  return (
    <tbody>
      <TableLoadingSpinner message={'Requesting your orders...'} isFetching={isFetching} />
      {isNotFound.isNotFound && (
        <Alert className="mt-5" variant="warning">
          {isNotFound.message}
        </Alert>
      )}
      {orders.map((order, key) =>
        <OrderRow key={key} handleGetOrders={handleGetOrders} {...order} />
      )}

    </tbody>
  )
}

export default MyOrdersTableBody
