import { useEffect, useState, useCallback, useRef } from 'react'
import TableLoadingSpinner from 'components/TableLoadingSpinner'
import { orderApi, sampleApi } from 'api/api'
import { Alert } from 'react-bootstrap'
import { getSamplesQueryStringGenerator } from 'util/queryStringGenerator'
import { ISearchResults } from 'contexts/SearchResultContext'
import FriendlyListRow from '../FriendlyListRow'

interface isNotFound {
  isNotFound: boolean
  message: string
}

interface MyOrderTableBodyProps {
  orderId: string
  isFetching: boolean
  isNotFound: isNotFound
  setIsFetching: (isFetching: boolean) => void
  setIsNotFound: (isNotFound: isNotFound) => void
}
export function MyOrderTableBody (props: MyOrderTableBodyProps): JSX.Element {
  const { orderId, isFetching, isNotFound, setIsFetching, setIsNotFound } = props
  const [searchResults, setSearchResults] = useState<ISearchResults>({ samples: [] })
  const isMounted = useRef(true)

  const getOrder = useCallback(async () => {
    setIsFetching(true)
    const order = await orderApi.get(orderId)
    const sampleIds = order.orderSamples.map((orderSample) => orderSample.sampleId)
    const searchResults = await sampleApi.get(getSamplesQueryStringGenerator(sampleIds))
    if (isMounted.current && searchResults && searchResults.samples.length > 0) {
      setIsFetching(false)
      setIsNotFound({ isNotFound: false, message: '' })
      setSearchResults(searchResults)
    } else if (isMounted.current) {
      setIsFetching(false)
      setIsNotFound({ isNotFound: true, message: 'No samples in this order' })
      setSearchResults({ samples: [] })
    }
  }, [orderId, setIsFetching, setIsNotFound])

  useEffect(() => {
    getOrder().catch(() => {
      if (isMounted.current) {
        setIsFetching(false)
        setIsNotFound({ isNotFound: true, message: 'No samples in this order' })
        setSearchResults({ samples: [] })
      }
    })
  }, [getOrder, setIsFetching, setIsNotFound])

  useEffect(() => {
    return function cleanup () {
      isMounted.current = false
    }
  }, [])

  return (
    <tbody>
      <TableLoadingSpinner message={'Requesting the order details...'} isFetching={isFetching} />
      {isNotFound.isNotFound && (
        <Alert className="mt-5" variant="warning">
          {isNotFound.message}
        </Alert>
      )}
      {searchResults.samples.length > 0 &&
        searchResults.samples.map((sample, key) => {
          return <FriendlyListRow key={key} {...sample} />
        })}
    </tbody>
  )
}

export default MyOrderTableBody
