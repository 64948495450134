import { ISearchTableToggleProps } from 'contexts/SearchResultContext/SearchTableContext'
import { CustomToggleButton } from 'components/custom/CustomToggleButton'
import { WithSearchTableToggleContext } from 'contexts/SearchResultContext/SearchTableContext/WithSearchTableContext'
import { useEffect, useState } from 'react'
import { dataJsonApi } from 'api/api'

export function SearchTableToggle (props: ISearchTableToggleProps): JSX.Element {
  const { groupByDonor, handleToggleClicked } = props

  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    (async function getTooltipMessage (): Promise<void> {
      const json = await dataJsonApi.get()
      setMessage(json.groupByDonor.message)
    })().catch(err => console.error(err))
  }, [])

  return (
    <CustomToggleButton
      isChecked={groupByDonor}
      handleToggleClicked={handleToggleClicked}
      name="Group By Donor"
      tooltipMessage={message}
    />
  )
}

export default WithSearchTableToggleContext(SearchTableToggle)
