
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { NavLink } from 'react-bootstrap'
import { IFilterSubItem } from 'contexts/FilterFacetContext'
import { getSampleTypeNameToDisplay } from 'util/presentation'

interface ISelectedFacetProps {
  filterItemName: string
  filterSubItem: IFilterSubItem
  handleFilterClicked: (filterItemName: string, filterSubItem: IFilterSubItem) => void
}

export function SelectedFacet (props: ISelectedFacetProps): JSX.Element {
  const { filterItemName, filterSubItem, handleFilterClicked } = props
  const handleClick = (): void => {
    handleFilterClicked(filterItemName, filterSubItem)
  }

  return (
    <li>
      <NavLink href="#" onClick={handleClick} className="btn btn-success">
        <CloseIcon />
        {[getSampleTypeNameToDisplay(filterItemName)]}: {filterSubItem.desc ? filterSubItem.desc : filterSubItem.id}
      </NavLink>
    </li>
  )
}

export default SelectedFacet
