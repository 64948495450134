export const LOCALSTORAGE = 'localStorage'
export const SESSIONSTORAGE = 'sessionStorage'

export function getFromStorage (key: string, type: string): string {
  try {
    const storage = window[type]
    const result = storage.getItem(key)
    return result || ''
  } catch {
    removeFromStorage(key, type)
    return ''
  }
}

export function saveToStorage (key: string, value: string, type: string): boolean {
  try {
    const storage = window[type]
    storage.setItem(key, value)
    return true
  } catch {
    return false
  }
}

export function removeFromStorage (key: string, type: string): boolean {
  try {
    const storage = window[type]
    storage.removeItem(key)
    return true
  } catch {
    return false
  }
}
