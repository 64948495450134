/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IOrder, orderApi } from 'api/api'
import RemoveTableRowButton from 'components/RemoveTableRowButton'

interface IOrderRowProps extends IOrder {
  handleGetOrders: () => void
}
export function OrderRow (props: IOrderRowProps): JSX.Element {
  const { id, created, handleGetOrders } = props
  const [isRemoving, setIsRemoving] = useState(false)
  const isMounted = useRef(true)
  const handleRemoveClick = async (): Promise<void> => {
    setIsRemoving(true)
    await orderApi.delete(id).catch(() => {
      alert(`Order ${id} cannot be deleted`)
    })
    if (isMounted.current) {
      setIsRemoving(false)
      handleGetOrders()
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <>
      <tr>
        <td className="align-middle p-4">{id}</td>
        <td className="align-middle p-4">{created}</td>
        <td className="align-middle text-right p-4"><Link to={`/myorder/${id}`}>View</Link></td>
        {isRemoving
          ? (
            <td className="align-middle text-right p-4">
              <Spinner animation="border" />
            </td>
            )
          : (
            <td className="align-middle text-right p-4">
              <RemoveTableRowButton message={'Delete this order ? Are you sure ?'} handleRemoveClicked={handleRemoveClick} isFetching={isRemoving} />
            </td>
            )}
      </tr>
    </>
  )
}

export default OrderRow
