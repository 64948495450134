
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/svg/logo.svg'

export function UniLogo (): JSX.Element {
  return (
    <Link to="/">
      <div className="nav-global-logo">
        <Logo />
        <span className="sr-only"></span>
      </div>
    </Link>
  )
}
