
import { NavLink } from 'react-router-dom'
import { WithNavContext } from 'contexts/HeaderContext/NavContext/WithNavContext'
import { INavProps } from 'contexts/HeaderContext/NavContext'

export function NavBrand (props: INavProps): JSX.Element {
  const { brand } = props
  return (
    <div className="nav nav-global-links d-none d-lg-flex">
      <div className="nav-item mr-50">
        <NavLink className="nav-link border-0" style={{ fontSize: '2rem' }} to="/">
          {brand}
        </NavLink>
      </div>
    </div>
  )
}

export default WithNavContext(NavBrand)
