import { ComponentType } from 'react'
import {
  ISearchTableToggleProps,
  SearchTableToggleContext,
  ISearchTablePaginProps,
  SearchTablePaginContext,
  IComputedSearchOutputState,
  ComputedSearchOutputStateContext
} from '.'

export function WithComputedSearchOutputStateContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof IComputedSearchOutputState>): JSX.Element {
    return (
      <ComputedSearchOutputStateContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </ComputedSearchOutputStateContext.Consumer>
    )
  }
}

export function WithSearchTableToggleContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchTableToggleProps>): JSX.Element {
    return (
      <SearchTableToggleContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchTableToggleContext.Consumer>
    )
  }
}

export function WithSearchTablePaginContext<T> (Component: ComponentType<T>) {
  return function newComponent (props: Omit<T, keyof ISearchTablePaginProps>): JSX.Element {
    return (
      <SearchTablePaginContext.Consumer>
        {(contextProps) => <Component {...(props as T)} {...contextProps} />}
      </SearchTablePaginContext.Consumer>
    )
  }
}
