export class UnauthorizedError extends Error {
  constructor () {
    super()
    this.message = 'Please login.'
  }
}

export class DataNotFoundError extends Error {
  constructor () {
    super()
    this.message = 'Data not found.'
  }
}

export class ServerError extends Error {
  constructor () {
    super()
    this.message = 'We are under maintainence now, please come back later.'
  }
}
