
export function FootNote (): JSX.Element {
  return (
    <small className="d-lg-flex justify-content-center">
      Use of this service is governed by
      <a className="p-0 m-0" href="http://www.cardiff.ac.uk/public-information/policies-and-procedures/it-regulations">
        &nbsp;University IT regulations.&nbsp;
      </a>
      Logging in to Cardiff University confirms that you accept and agree to be bound by these regulations.
    </small>
  )
}
